import { useState } from 'react'
import { List as UIList } from '@faststore/ui'
import Accordion, { AccordionItem } from 'src/components/ui/Accordion'

import { links, linksmobile } from './FooterData'
import SocialNetwork from './SocialNetwork'

import vetIcon from 'src/images/vetIcon.png'

type LinkItem = {
  href?: string
  name: string
}
interface LinksListProps {
  items: LinkItem[]
}
function LinksList({ items }: LinksListProps) {
  return (
    <UIList>
      {items.map((item) => (
        <li className="text__list" key={item.name}>
          {item.href ? (
            <a
              data-fs-link
              data-fs-link-variant="footer"
              data-fs-link-inverse={false}
              href={item.href}
            >
              {item.name}
            </a>
          ) : (
            <span>{item.name}</span>
          )}
        </li>
      ))}
    </UIList>
  )
}

function FooterLinks() {
  const [indicesExpanded, setIndicesExpanded] = useState<Set<number>>(
    new Set([])
  )

  const onChange = (index: number) => {
    if (indicesExpanded.has(index)) {
      setIndicesExpanded(new Set([]))
    } else {
      setIndicesExpanded(new Set([index]))
    }
  }

  return (
    <section className="footer__links">
      <div className="display-mobile">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Accordion expandedIndices={indicesExpanded} onChange={onChange}>
            {linksmobile.map((section, index) => (
              <AccordionItem
                className={
                  indicesExpanded.has(index) ? 'accordion__footer-focus' : ''
                }
                key={section.title}
                isExpanded={indicesExpanded.has(index)}
                buttonLabel={section.title}
              >
                <LinksList items={section.items} />
              </AccordionItem>
            ))}
          </Accordion>
          <a
            href="https://www.salute.gov.it/LogoCommercioElettronicoVET/CercaSitoEComm?useMode=getDettaglio&lingua=italiano&id=5907&area=veterinari&menu=vendite&VOLID=XCEC0000514"
            style={{
              marginTop: '1.125rem',
              marginLeft: 'calc((100vw - 315px - 48px)/2 + 24px)',
            }}
          >
            <img src={vetIcon} />
          </a>
        </div>
      </div>
      <div className="social__footer-mobile">
        <SocialNetwork />
      </div>

      <div className="hidden-mobile">
        <div className="footer__links-columns">
          {links.map((section, index, array) => (
            <nav key={section.title}>
              <p className="text__title-mini">{section.title}</p>
              <LinksList items={section.items} />
              {array.length - 1 === index && <SocialNetwork />}
              {array.length - 1 === index && (
                <a href="https://www.salute.gov.it/LogoCommercioElettronicoVET/CercaSitoEComm?useMode=getDettaglio&lingua=italiano&id=5907&area=veterinari&menu=vendite&VOLID=XCEC0000514">
                  <img style={{ marginTop: '1.125rem' }} src={vetIcon} />
                </a>
              )}
            </nav>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FooterLinks