import type { IconProps } from './IconsTypes'

export const NegocizioPiuVicino = ({
  width = 14,
  height = 14,
  viewBox = '0 0 14 14',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m9 13-.224.447a.5.5 0 0 0 .448 0L9 13Zm4-2 .224.447A.5.5 0 0 0 13.5 11H13Zm0-10h.5a.5.5 0 0 0-.724-.447L13 1ZM1 3l-.224-.447A.5.5 0 0 0 .5 3H1Zm0 10H.5a.5.5 0 0 0 .724.447L1 13ZM5 1l.224-.447a.5.5 0 0 0-.448 0L5 1Zm3.5 2v10h1V3h-1Zm.724 10.447 4-2-.448-.894-4 2 .448.894ZM13.5 11V1h-1v10h1ZM12.776.553l-4 2 .448.894 4-2-.448-.894ZM.5 3v10h1V3h-1Zm.724 10.447 4-2-.448-.894-4 2 .448.894ZM5.5 11V1h-1v10h1ZM4.776.553l-4 2 .448.894 4-2-.448-.894Zm0 .894 4 2 .448-.894-4-2-.448.894Zm4.448 11.106-4-2-.448.894 4 2 .448-.894Z"
      fill={color}
    />
  </svg>
)
