import type { SearchEvent } from '@faststore/sdk'
import { sendAnalyticsEvent } from '@faststore/sdk'
import type {
  SearchInputProps as UISearchInputProps,
  SearchInputRef as UISearchInputRef,
} from '@faststore/ui'
import { SearchInput as UISearchInput } from '@faststore/ui'
import type { CSSProperties } from 'react'
import {
  Suspense,
  forwardRef,
  lazy,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import useSearchHistory from 'src/sdk/search/useSearchHistory'
import type { SearchInputContextValue } from 'src/sdk/search/useSearchInput'
import {
  SearchInputProvider,
  formatSearchPath,
} from 'src/sdk/search/useSearchInput'
import useOnClickOutside from 'src/sdk/ui/useOnClickOutside'

import './search-input.scss'

const SearchDropdown = lazy(
  () => import('src/components/search/SearchDropdown')
)

export type SearchInputProps = {
  onSearchClick?: () => void
  buttonTestId?: string
  containerStyle?: CSSProperties
} & Omit<UISearchInputProps, 'onSubmit'>

export type SearchInputRef = UISearchInputRef & { resetSearchInput: () => void }

const sendAnalytics = async (term: string) => {
  sendAnalyticsEvent<SearchEvent>({
    name: 'search',
    params: { search_term: term },
  })
}

const SearchInput = forwardRef<SearchInputRef, SearchInputProps>(
  function SearchInput({ onSearchClick, containerStyle, ...otherProps }, ref) {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const searchQueryDeferred = searchQuery
    const [searchDropdownVisible, setSearchDropdownVisible] =
      useState<boolean>(false)

    const [isFocused, setIsFocused] = useState<boolean>(false)
    const searchRef = useRef<HTMLDivElement>(null)
    const { addToSearchHistory } = useSearchHistory()

    useImperativeHandle(ref, () => ({
      resetSearchInput: () => setSearchQuery(''),
    }))

    const onSearchInputSelection: SearchInputContextValue['onSearchInputSelection'] =
      (term, path) => {
        addToSearchHistory(term)
        sendAnalytics(term)
        setSearchDropdownVisible(false)
        setSearchQuery(term)
        window.location.href = path
      }

    useOnClickOutside(searchRef, () => {
      setSearchDropdownVisible(false)
    })

    const evetcloseSearchDropdown = () => {
      setSearchDropdownVisible(false)
    }

    return (
      <div
        ref={searchRef}
        data-fs-search-input-wrapper
        data-fs-search-input-dropdown-visible={searchDropdownVisible}
        style={containerStyle}
        className={searchDropdownVisible ? 'search-dropdown-visible' : ''}
      >
        <SearchInputProvider onSearchInputSelection={onSearchInputSelection}>
          <div data-fs-search-input-container>
            <div
              className={`${
                searchQuery !== '' ? 'borderVisible' : ''
              } ui-search-input-container`}
            >
              <UISearchInput
                data-fs-search-input
                ref={ref}
                placeholder="Cerca tra oltre 10.000 prodotti. Cerca per prodotto, categoria, brand..."
                onChange={(e) => setSearchQuery(e.target.value)}
                onSubmit={(term) => {
                  const path = formatSearchPath(term)

                  onSearchInputSelection(term, path)
                }}
                onFocus={() => {
                  setIsFocused(true)
                  setSearchDropdownVisible(true)
                }}
                onBlur={() => setIsFocused(false)}
                value={searchQuery}
                {...otherProps}
              />
              {searchQuery && (
                <div data-fs-search-input-x-container>
                  <button
                    data-fs-search-input-x-button
                    onClick={() => setSearchQuery('')}
                  >
                    x
                  </button>
                </div>
              )}
            </div>

            {searchDropdownVisible && (
              <>
                <div
                  className={`overlay-dropdown ${
                    searchDropdownVisible ? 'visible' : ''
                  }`}
                />
                <Suspense fallback={null}>
                  <div
                    className={`shadowVisible searchDropdownContainer ${
                      searchDropdownVisible ? 'visible' : ''
                    } ${isFocused ? 'isFocused' : ''}`}
                    data-fs-search-input-dropdown-wrapper
                  >
                    <SearchDropdown
                      term={searchQueryDeferred}
                      closeSearchDropdownEvent={evetcloseSearchDropdown}
                    />
                  </div>
                </Suspense>
              </>
            )}
          </div>
        </SearchInputProvider>
      </div>
    )
  }
)

export default SearchInput
