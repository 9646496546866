import type { PropsWithChildren } from 'react'

import 'src/styles/pages/layout.scss'

function Layout({ children }: PropsWithChildren<unknown>) {
  const noScriptIframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KHXZHM4"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`

  return (
    <>
      <noscript dangerouslySetInnerHTML={{ __html: noScriptIframe }} />
      <div id="layout">
        <div id="main-parent-element" className="main-full-width">
          <main>{children}</main>
        </div>
      </div>
    </>
  )
}

export default Layout
