import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import axios from 'axios'
import { useCallback, useState } from 'react'
import { useCart } from 'src/sdk/cart/useCart'
import type { CartItem } from 'src/sdk/cart/validate'
import { getCartFromOrderForm, validateCart } from 'src/sdk/cart/validate'

import { updateCartItem } from './handleCartItems'

export const useUpdateQuantity = (
  item: CartItem | null,
  setIsVTEXValidating: (isValidating: boolean) => void
) => {
  const {
    updateItemQuantity: defaultUpdateQuantity,
    id: cartId,
    setCart: setLocaleCart,
  } = useCart()
  const {
    currency: { code },
  } = useSession()

  const validateAfterUpdate = async (newCartId: string) => {
    const { data: savedOrderForm } = await axios.get(
      `/api/checkout/getCartInformation?orderFormId=${newCartId}`
    )

    const savedCart = getCartFromOrderForm(savedOrderForm)

    const validatedCart = await validateCart(savedCart)

    if (validatedCart) setLocaleCart(validatedCart)
  }

  const [error, setError] = useState<string | undefined>(undefined)

  const updateItemQuantity = useCallback(
    (updatedQuantity: number) => {
      if (!item) {
        return
      }

      setIsVTEXValidating(true)

      const itemCategory = item.itemOffered.breadcrumbList?.itemListElement
        .slice(0, -1)
        .map((x) => x.name)

      const itemListName = itemCategory.join(' ')

      const isKit = item.isKit

      sendAnalyticsEvent<UnknownEvent>({
        name:
          item.quantity > updatedQuantity ? 'remove_from_cart' : 'add_to_cart',
        params: {
          currency: code as CurrencyCode,
          value: item.price * item.quantity,
          orderFormId: cartId,
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: item.itemOffered.isVariantOf.name,
            bundle_discount: +(item.listPrice - item.price).toFixed(2),
            bundle_discount_percentage: +(
              1 -
              item.price / item.listPrice
            ).toFixed(2),
          }),
          items: [
            {
              index: 0,
              item_list_id: null,
              item_category: itemCategory,
              item_list_name: itemListName,
              item_id: item.itemOffered.isVariantOf.productGroupID,
              item_name: item.itemOffered.isVariantOf.name,
              item_brand: item.itemOffered.brand.name,
              item_variant: item.itemOffered.sku,
              quantity: 1,
              price: item.price,
              discount: +(item.listPrice - item.price).toFixed(2),
              full_price: item.listPrice,
              discount_percentage: +(1 - item.price / item.listPrice).toFixed(
                2
              ),
              currency: code as CurrencyCode,
              item_variant_name: item.itemOffered.name,
              product_reference_id: Number(item.itemOffered.gtin),
              coupon: null,
              // Adding optional kit parameters
              ...(isKit && {
                bundle_label: item.itemOffered.isVariantOf.name,
                bundle_discount: +(item.listPrice - item.price).toFixed(2),
                bundle_discount_percentage: +(
                  1 -
                  item.price / item.listPrice
                ).toFixed(2),
              }),
            },
          ],
        },
      })

      updateCartItem({ ...item, quantity: updatedQuantity }, cartId)
        .then(() => {
          defaultUpdateQuantity(item.id, updatedQuantity)
          setIsVTEXValidating(false)
        })
        .catch(() => {
          validateAfterUpdate(cartId)
          setIsVTEXValidating(false)
          setError('Questo prodotto non è più presente nel carrello.')
          setTimeout(() => {
            setError(undefined)
          }, 2000)
        })
    },
    [
      code,
      item,
      defaultUpdateQuantity,
      cartId,
      setIsVTEXValidating,
      setLocaleCart,
    ]
  )

  return { updateItemQuantity, error }
}
