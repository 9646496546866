export const mockImageMenu = {
  cane: [
    {
      src: 'Cane.webp',
      alt: 'Cane',
    },
  ],
  gatto: [
    {
      src: 'Gatto.webp',
      alt: 'Gatto',
    },
  ],
  pesci: [
    {
      src: 'Pesci.webp',
      alt: 'Pesci',
    },
  ],
  tartarughe: [
    {
      src: 'Tartarughe.webp',
      alt: 'Tartarughe',
    },
  ],
  rettili: [
    {
      src: 'Rettili.webp',
      alt: 'Rettili',
    },
  ],
  uccelli: [
    {
      src: 'Uccelli.webp',
      alt: 'Uccelli',
    },
  ],
  roditori: [
    {
      src: 'Roditori.webp',
      alt: 'Roditori',
    },
  ],
  casa: [
    {
      src: 'Casa.webp',
      alt: 'Casa',
    },
  ],
  altrianimali: [
    {
      src: 'AltriAnimali.webp',
      alt: 'Altri Animali',
    },
  ],
  promozione: [
    {
      src: 'Promozioni.webp',
      alt: 'Promozioni',
      url: '',
    },
  ],
  piccolianimali: [
    {
      src: 'PiccoliAnimali.webp',
      alt: 'Piccoli Animali',
    },
  ],
}
