import { Link as UILink } from '@faststore/ui'
import { useState } from 'react'
import { RenderClient } from 'src/components/RenderClient'
import CartToggle from 'src/components/cart/CartToggle'
import { ArcaplanetLogoBrand } from 'src/components/icons/ArcaplanetLogoBrand'
import { Ellipse } from 'src/components/icons/Ellipse'
import { HamburgerMenu } from 'src/components/icons/HamburguerMenu'
import { MagnifyGlassMobile } from 'src/components/icons/MagnifyGalssMobile'
import { WishListNewUpdated } from 'src/components/icons/WishListNewUpdated'
import SearchInput from 'src/components/search/SearchInput'
import { ButtonIcon } from 'src/components/ui/Button'
import ButtonSignIn from 'src/components/ui/Button/ButtonSignIn'
import SlideOver from 'src/components/ui/SlideOver'
import { mark } from 'src/sdk/tests/mark'
import './navbar.scss'

import storeConfig from '../../../../store.config'
import { Menu } from '../Menu'
import MainModal from '../MenuMobile/MainModal/MainModal'

function Navbar() {
  const [showMenu, setShowMenu] = useState(false)

  const handleCloseSlideOver = () => {
    setShowMenu(false)
  }

  return (
    <>
      <header className="navbar layout__content-full">
        <section className="navbar__row">
          <div className="hamburger-menu">
            <ButtonIcon
              data-fs-button-menu
              aria-label="Open Menu"
              icon={<HamburgerMenu />}
              onClick={() => setShowMenu(true)}
            />
          </div>
          <a
            href="/"
            aria-label="Go to Arcaplanet home"
            title="Go to Faststore home"
            className="navbar__logo align-bottom"
          >
            <ArcaplanetLogoBrand />
          </a>
          <div className="desktop" data-store-search-expanded-desktop>
            <SearchInput icon={<Ellipse />} />
          </div>
          <div className="navbar__buttons">
            <RenderClient>
              <ButtonSignIn />
            </RenderClient>
            <UILink as="div" data-fs-button-wishlist aria-label="Open Wishlist">
              <a href={`${storeConfig.accountUrl}#/wishlist`}>
                <WishListNewUpdated width={32} height={32} />
              </a>
            </UILink>
            <RenderClient>
              <CartToggle />
            </RenderClient>
          </div>
        </section>
        <div className="navbar__navigation">
          <div className="layout__content">
            <Menu />
          </div>
        </div>
        <SlideOver
          isOpen={showMenu}
          onDismiss={handleCloseSlideOver}
          size="full"
          direction="leftSide"
          className="modal__mobile tsticky top-12 z-50"
        >
          <MainModal closeMainModal={handleCloseSlideOver} />
        </SlideOver>
      </header>
      <div className="mobile" data-store-search-expanded-mobile>
        <SearchInput
          placeholder="Cerca il tuo prodotto"
          testId="store-input-mobile"
          data-buttontestid="store-input-mobile-button"
          icon={<MagnifyGlassMobile />}
        />
      </div>
    </>
  )
}

Navbar.displayName = 'Navbar'

export default mark(Navbar)
