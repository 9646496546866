// import { CloseIcon } from 'src/components/icons/CloseIcon'
import { useSharedCartContext } from 'src/contexts/shared-cart-context'

import storeConfig from '../../../../store.config'

function SharedCartModal() {
  const { showSharedCartModal /* , setShowSharedCartModal */ } =
    useSharedCartContext()

  // const handleCloseModalClick = () => {
  //   setShowSharedCartModal(false)
  // }

  return showSharedCartModal ? (
    <div id="shared-cart-modal">
      <div
        className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-[10000]"
        // onClick={handleCloseModalClick}
      >
        <div className="bg-white w-[650px] max-w-[90%] rounded-lg leading-[120%] tracking-wide">
          <div className="flex items-start md:items-center justify-between gap-6 p-6 sm:!px-10">
            <h3 className="font-semibold text-lg">
              Oops... Sessione è scaduta
            </h3>
            {/* <button
              onClick={handleCloseModalClick}
              className="flex items-center justify-center bg-transparent bg-none outline-none cursor-pointer"
            >
              <CloseIcon />
            </button> */}
          </div>
          <div className="text-gray-600 text-base p-6 sm:!px-10 border-y border-y-gray-[#e5e7eb]">
            Effettua il login per continuare lo shopping e ritrovare il tuo
            carrello.
          </div>
          <div className="py-6 px-4 sm:!px-10">
            <a
              className="block w-full py-3 px-5 bg-yellow rounded-lg text-center font-semibold text-sm no-underline mb-2"
              href={storeConfig.loginUrl}
            >
              Accedi
            </a>
            <a
              className="block w-full py-3 px-5 text-center text-sm underline"
              href="/logout"
            >
              Continua come ospite
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default SharedCartModal
