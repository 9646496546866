import type { IconProps } from './IconsTypes'

export const WishListNewUpdated = ({
  width = 18,
  height = 18,
  viewBox = '0 0 32 32',
  color = '#14181F',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m16 6.449-.76.65.76.887.76-.887-.76-.65ZM16 28l-.517.856a1 1 0 0 0 1.034 0L16 28ZM3.667 11.919c0-3.555 2.03-5.728 4.484-6.549 2.514-.84 5.398-.247 7.09 1.729l1.519-1.3c-2.307-2.695-6.09-3.38-9.243-2.326-3.213 1.075-5.85 3.973-5.85 8.446h2Zm13.092-4.82c1.694-1.976 4.578-2.57 7.09-1.729 2.455.821 4.484 2.994 4.484 6.548h2c0-4.472-2.636-7.37-5.848-8.445-3.154-1.055-6.936-.37-9.244 2.325l1.518 1.301Zm11.574 4.82c0 1.734-.724 3.549-1.916 5.346-1.188 1.79-2.79 3.486-4.421 4.963a47.905 47.905 0 0 1-4.488 3.57 45.672 45.672 0 0 1-1.888 1.261c-.046.03-.082.051-.105.065l-.025.016a.203.203 0 0 0-.006.003l-.001.001L16 28a160.263 160.263 0 0 0 .518.855l.003-.001.009-.006a5.615 5.615 0 0 0 .15-.092c.101-.064.25-.157.436-.278a49.908 49.908 0 0 0 6.222-4.767c1.701-1.54 3.433-3.362 4.745-5.34 1.308-1.971 2.25-4.173 2.25-6.452h-2ZM16 28l.517-.856a47.902 47.902 0 0 1-6.512-4.915c-1.633-1.478-3.234-3.174-4.422-4.964-1.192-1.797-1.916-3.612-1.916-5.346h-2c0 2.279.942 4.481 2.25 6.452 1.312 1.978 3.044 3.8 4.745 5.34a49.921 49.921 0 0 0 4.679 3.723 47.797 47.797 0 0 0 1.98 1.322l.118.073.031.02.01.005.002.001s.001.001.518-.855Z"
      fill={color}
      fillOpacity={0.92}
    />
  </svg>
)
