export const AdditionalPropertiesToShowConstant = [
  'Gusto',
  'Esigenze',
  'Caratteristica nutrizionale',
  'Funzione alimentare',
  'Tipo di alimento',
  'Età',
  'Razza',
  'Formato',
  'Conservazione',
  'Consistenza',
  'Formato del pack',
  'Taglia',
  'Funzione',
  'Materiale',
  'Forma',
  'Tipo di cibo',
  'Tipo di pesce',
  'Tipo di roditore',
  'Tipo di uccello',
]
