import type { IconProps } from './IconsTypes'

export const VisaIcon = ({
  width = 47,
  height = 30,
  viewBox = '0 0 46 30',
  color = '#F8F8F8',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={0.5} fill={color}>
      <path d="M40.113 1.554a4.833 4.833 0 0 1 4.834 4.833v16.917a4.833 4.833 0 0 1-4.834 4.833H6.28a4.833 4.833 0 0 1-4.833-4.833V6.387A4.833 4.833 0 0 1 6.28 1.554h33.833Zm0-1.208H6.28A6.042 6.042 0 0 0 .238 6.387v16.917a6.042 6.042 0 0 0 6.042 6.042h33.833a6.041 6.041 0 0 0 6.042-6.042V6.387A6.042 6.042 0 0 0 40.113.346Z" />
      <path d="m19.282 10.689-3.468 8.325H13.53l-1.68-6.585a.905.905 0 0 0-.507-.737 9.294 9.294 0 0 0-2.103-.713v-.242h3.625a1.015 1.015 0 0 1 .991.858l.894 4.833 2.224-5.667 2.308-.072Zm8.857 5.607c0-2.188-3.021-2.308-2.997-3.3 0-.29.29-.615.906-.688a4.01 4.01 0 0 1 2.115.363l.374-1.765a5.959 5.959 0 0 0-2.005-.362c-2.115 0-3.625 1.124-3.625 2.755 0 1.208 1.063 1.86 1.872 2.26.81.398 1.112.676 1.112 1.039 0 .556-.664.797-1.28.81a4.423 4.423 0 0 1-2.2-.52l-.423 1.824c.771.297 1.591.444 2.417.435 2.247 0 3.71-1.111 3.721-2.851h.013Zm5.57 2.718h1.97l-1.716-8.325H32.15a.991.991 0 0 0-.918.616l-3.202 7.71h2.247l.435-1.209h2.743l.254 1.208Zm-2.417-2.948 1.136-3.117.677 3.105-1.813.012Zm-8.99-5.377-1.764 8.325h-2.102L20.2 10.69h2.102Z" />
    </g>
  </svg>
)
