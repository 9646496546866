import axios from 'axios'

import type { CartItem } from './validate'

export const updateCartItem = async (
  { itemOffered: { sku, additionalProperty }, ...otherProps }: CartItem,
  cartId: string
) => {
  await axios.post('/api/checkout/updateCartItem', {
    orderFormId: cartId,
    newItem: {
      ...otherProps,
      itemOffered: {
        sku,
        additionalProperty,
      },
    },
  })
}

export const addCartItem = async (
  { itemOffered: { sku, additionalProperty }, ...otherProps }: CartItem,
  cartId: string
) => {
  await axios.post('/api/checkout/addCartItem', {
    orderFormId: cartId,
    newItem: {
      ...otherProps,
      itemOffered: {
        sku,
        additionalProperty,
      },
    },
  })
}

export const removeCartItem = async (
  cartId: string,
  { itemOffered: { sku, additionalProperty }, ...otherProps }: CartItem
) => {
  await axios.post('/api/checkout/removeCartItem', {
    orderFormId: cartId,
    newItem: {
      ...otherProps,
      itemOffered: {
        sku,
        additionalProperty,
      },
    },
  })
}
