import type { MenuProps } from '../../typings/menu'
import {
  menuAcquistaTuttiIBrand,
  menuCane,
  menuCasa,
  menuGatto,
  menuPiccoliAnimali,
  menuPromozione,
  menuArcacard,
} from './index'

const menu: MenuProps = {
  promozioni: menuPromozione,
  cane: menuCane,
  gatto: menuGatto,
  piccolianimali: menuPiccoliAnimali,
  // pesci: menuPesci,
  // uccelli: menuUccelli,
  // roditori: menuRoditori,
  // tartarughe: menuTartarughe,
  // rettili: menuRettili,
  // altrianimali: menuAltriAnimali,
  personaecasa: menuCasa,
  acquistatuttiibrand: menuAcquistaTuttiIBrand,
  arcacard: menuArcacard,
}

export default menu
