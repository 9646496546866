import type { IconProps } from './IconsTypes'

export const BellNotification = ({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 11.333h.5a.5.5 0 0 0-.5-.5v.5Zm-4 0v-.5a.5.5 0 0 0-.5.5H6ZM9.22 2.681a.5.5 0 0 0 .292-.957l-.292.957ZM3.203 9.464l.353.353-.353-.353Zm-.34.34-.354-.353.353.353Zm10.275 0 .354-.353-.354.353Zm.014-3.525a.5.5 0 1 0-.997.076l.998-.076ZM9.5 11.333V12h1v-.667h-1ZM6.5 12v-.667h-1V12h1Zm-.5-.167h4v-1H6v1ZM8 13.5A1.5 1.5 0 0 1 6.5 12h-1A2.5 2.5 0 0 0 8 14.5v-1ZM9.5 12A1.5 1.5 0 0 1 8 13.5v1a2.5 2.5 0 0 0 2.5-2.5h-1Zm4-8c0 1.196-.97 2.166-2.166 2.166v1A3.167 3.167 0 0 0 14.5 4h-1Zm-2.166 2.166A2.167 2.167 0 0 1 9.167 4h-1a3.167 3.167 0 0 0 3.167 3.166v-1ZM9.167 4c0-1.197.97-2.167 2.167-2.167v-1A3.167 3.167 0 0 0 8.167 4h1Zm2.167-2.167c1.196 0 2.166.97 2.166 2.167h1A3.167 3.167 0 0 0 11.334.833v1ZM9.51 1.724A5.166 5.166 0 0 0 8 1.5v1c.424 0 .833.063 1.218.18l.292-.956ZM8 1.5a5.167 5.167 0 0 0-5.167 5.166h1A4.167 4.167 0 0 1 8 2.5v-1ZM2.833 6.666v2.482h1V6.666h-1Zm0 2.482c0-.014.005-.028.015-.038l.707.707a.947.947 0 0 0 .278-.67h-1Zm.015-.038-.34.34.707.708.34-.34-.707-.708Zm-.34.34a1.167 1.167 0 0 0-.342.826h1c0-.044.017-.087.049-.118l-.707-.707Zm-.342.826v.39h1v-.39h-1Zm0 .39c0 .645.522 1.167 1.167 1.167v-1a.167.167 0 0 1-.167-.167h-1Zm1.167 1.167h9.333v-1H3.334v1Zm9.333 0c.644 0 1.167-.522 1.167-1.167h-1a.167.167 0 0 1-.167.167v1Zm1.167-1.167v-.39h-1v.39h1Zm0-.39c0-.31-.123-.606-.342-.825l-.707.707a.167.167 0 0 1 .049.118h1Zm-.342-.825-.34-.34-.708.706.34.34.708-.706Zm-.34-.34c.01.01.015.023.015.037h-1c0 .251.1.492.277.67l.707-.708Zm.015.037V6.666h-1v2.482h1Zm0-2.482c0-.13-.005-.259-.014-.387l-.998.076c.008.102.012.206.012.311h1Z"
      fill={color}
      opacity={0.9}
    />
  </svg>
)
