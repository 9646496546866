import type { IconProps } from './IconsTypes'

export const ArrowRightMenuCustom = ({
  width = 9,
  height = 16,
  viewBox = '0 0 9 16',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L8 8L1 15"
      stroke="#666666"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
