import type { IconProps } from './IconsTypes'

export const WashIcon = ({
  width = 14,
  height = 19,
  viewBox = '0 0 14 19',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7 1.512.443-.605a.75.75 0 0 0-.885 0L7 1.512ZM9.235 13.72l-.537-.523.537.523ZM11.084 11a.75.75 0 0 0-1.5 0h1.5Zm-3.506 2.922a.75.75 0 0 0 .511 1.41l-.511-1.41Zm6.006-2.665c0-3-1.589-5.604-3.09-7.407a20.141 20.141 0 0 0-2.775-2.73A11.391 11.391 0 0 0 7.45.913L7.445.909 7.444.908C7.442.906 7.442.906 7 1.511a157.145 157.145 0 0 1-.443.606h.002a1.727 1.727 0 0 1 .052.04c.039.03.097.074.172.134a18.64 18.64 0 0 1 2.558 2.516c1.415 1.7 2.743 3.967 2.743 6.448h1.5ZM7 1.512 6.557.907 6.555.91l-.004.003a4.175 4.175 0 0 0-.07.053l-.2.155a20.14 20.14 0 0 0-2.775 2.729C2.007 5.652.418 8.257.418 11.256h1.5c0-2.481 1.328-4.749 2.743-6.447a18.64 18.64 0 0 1 2.557-2.517 9.874 9.874 0 0 1 .225-.173l.001-.001L7 1.512ZM.417 11.256c0 1.804.686 3.54 1.917 4.825l1.083-1.038c-.957-.999-1.5-2.36-1.5-3.787h-1.5Zm1.917 4.825A6.462 6.462 0 0 0 7 18.096v-1.5a4.962 4.962 0 0 1-3.583-1.553l-1.083 1.038ZM7 18.096a6.462 6.462 0 0 0 4.667-2.015l-1.083-1.038A4.961 4.961 0 0 1 7 16.596v1.5Zm4.667-2.015a6.978 6.978 0 0 0 1.917-4.825h-1.5a5.478 5.478 0 0 1-1.5 3.787l1.083 1.038Zm-1.895-1.838a4.646 4.646 0 0 0 1.312-3.244h-1.5c0 .828-.32 1.617-.886 2.197l1.074 1.047ZM8.09 15.33a4.488 4.488 0 0 0 1.683-1.088l-1.074-1.047c-.32.328-.704.574-1.12.725l.511 1.41Z"
      fill={color}
    />
  </svg>
)
