import type { IconProps } from './IconsTypes'

export const MenuMobileTartaruga = ({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill={color}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.98 19.009c-3.759 0-6.832-3.073-6.832-6.832V9.994c0-3.759 3.073-6.832 6.833-6.832 3.759 0 6.832 3.073 6.832 6.832v2.183c0 3.759-3.073 6.832-6.832 6.832Z"
      stroke="#000"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.98 16.48a4.234 4.234 0 0 1-4.221-4.222V9.928A4.234 4.234 0 0 1 9.98 5.705a4.234 4.234 0 0 1 4.222 4.222v2.332c.007 2.318-1.897 4.221-4.222 4.221ZM7.846 3.509A2.159 2.159 0 0 1 9.98 1a2.159 2.159 0 0 1 2.134 2.509"
      stroke="#000"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.98 5.697v2.23L7.818 9.912v2.604L9.98 14.46v2.02M7.818 9.913l-1.7-1.693M7.818 12.516l-1.7 1.441M13.849 8.22l-1.707 1.692L9.98 7.927M12.142 9.912v2.604l1.706 1.441M9.98 14.46l2.162-1.944M6.785 3.97C2.611 1.857 1 5.698 1 5.698s.136 1.38 1.162.66c0 0 .721-.816 2.006.075M13.216 3.97C17.39 1.857 19 5.698 19 5.698s-.136 1.38-1.163.66c0 0-.72-.816-2.005.075M3.277 13.488s-3.066 2.95.612 5.52c0 0 .564.108.632-.68 0 0-.619-1.203 0-2.06M16.67 13.488s3.066 2.95-.612 5.52c0 0-.564.108-.632-.68 0 0 .618-1.203 0-2.06"
      stroke="#000"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
