import { renderToString } from 'react-dom/server'

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export default capitalizeFirstLetter

export const formatSvgToDataURL = (a: any) => {
  const svgString = renderToString(a)
  return `data:image/svg+xml,${encodeURIComponent(svgString)}`
}

export const createHTMLFromString = (a: string) => {
  return new DOMParser().parseFromString(formatSvgToDataURL(a), 'text/html')
    .body.childNodes[0]
}
