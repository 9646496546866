import type { IconProps } from './IconsTypes'

export const TwitterIcon = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  color = '#FFF',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        d="M22.362 5.785c.01.233.012.466.012.7 0 7.07-4.973 15.23-14.067 15.23-2.793 0-5.391-.89-7.58-2.405.387.042.78.074 1.179.074 2.316 0 4.45-.859 6.142-2.3-2.164-.032-3.99-1.59-4.62-3.71.302.063.613.095.932.095.45 0 .885-.063 1.303-.19-2.264-.488-3.969-2.65-3.969-5.247V7.97c.667.392 1.43.635 2.242.667-1.329-.964-2.202-2.607-2.202-4.462 0-.975.243-1.897.671-2.692 2.438 3.243 6.083 5.374 10.192 5.596a5.827 5.827 0 0 1-.128-1.219c0-2.957 2.214-5.352 4.945-5.352 1.422 0 2.706.646 3.608 1.685A9.28 9.28 0 0 0 24.164.9c-.37 1.25-1.154 2.3-2.177 2.957 1.001-.127 1.956-.413 2.841-.837a10.565 10.565 0 0 1-2.466 2.766Z"
        fill={color}
      />
    </svg>
  )
}
