import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import { useCallback } from 'react'

import { useUI } from '../ui'
import { useCart } from './useCart'

export const useCartToggleButton = () => {
  const { totalItems, items, total, id: cartId } = useCart()
  const {
    currency: { code },
  } = useSession()

  const { displayMinicart, openMinicart, closeMinicart } = useUI()

  const onClick = useCallback(() => {
    if (displayMinicart) {
      closeMinicart()
    } else {
      openMinicart()

      const eventItems = items.map((item) => {
        const itemCategory = item?.itemOffered?.breadcrumbList?.itemListElement
          .slice(0, -1)
          .map((x) => x.name)

        const itemListName = itemCategory?.join(' ')

        const isKit = item.itemOffered?.stockKeepingUnit?.isKit

        return {
          index: 0,
          item_list_id: null,
          item_category: itemCategory,
          item_list_name: itemListName,
          item_id: item.itemOffered.isVariantOf.productGroupID,
          item_name: item.itemOffered.isVariantOf.name,
          item_brand: item.itemOffered.brand.name,
          item_variant: item.itemOffered.sku,
          quantity: item.quantity,
          price: item.price,
          discount: +(item.listPrice - item.price).toFixed(2),
          full_price: item.listPrice,
          discount_percentage: +(1 - item.price / item.listPrice).toFixed(2),
          currency: code as CurrencyCode,
          item_variant_name: item.itemOffered.name,
          product_reference_id: Number(item.itemOffered.gtin),
          coupon: null,
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: item.itemOffered.isVariantOf.name,
            bundle_discount: +(item.listPrice - item.price).toFixed(2),
            bundle_discount_percentage: +(
              1 -
              item.price / item.listPrice
            ).toFixed(2),
          }),
        }
      })

      const kitItems = items.filter(
        (item) => item.itemOffered?.stockKeepingUnit?.isKit
      )
      const hasKits = kitItems?.length > 0
      const hasMoreThanOneKit = kitItems?.length > 1
      const kitItemsMapped = kitItems?.map((item) => ({
        bundle_label: item.itemOffered?.isVariantOf?.name,
        bundle_discount: hasMoreThanOneKit
          ? (item.listPrice - item.price).toFixed(2)
          : +(item.listPrice - item.price).toFixed(2),
        bundle_discount_percentage: hasMoreThanOneKit
          ? (1 - item.price / item.listPrice).toFixed(2)
          : +(1 - item.price / item.listPrice).toFixed(2),
      }))
      const formattedKitInfo = hasMoreThanOneKit
        ? kitItemsMapped.reduce(
            (
              acc: Record<string, string>,
              curr: Record<string, string | number>
            ) => {
              for (const prop of Object.keys(curr)) {
                if (!acc.hasOwnProperty(prop)) {
                  acc[prop] = curr[prop].toString()
                } else {
                  acc[prop] = acc[prop].concat(` | ${curr[prop]}`)
                }
              }
              return acc
            },
            {}
          )
        : kitItemsMapped[0]

      sendAnalyticsEvent<UnknownEvent>({
        name: 'view_cart',
        params: {
          currency: code as CurrencyCode,
          value: total,
          orderFormId: cartId,
          // Adding optional kit parameters
          ...(hasKits && formattedKitInfo),
          items: eventItems,
        },
      })
    }
  }, [closeMinicart, displayMinicart, openMinicart, items, total, code])

  return {
    onClick,
    'data-testid': 'cart-toggle',
    'data-items': totalItems,
  }
}
