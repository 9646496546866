export const isAnalyticsEvent = (name: string) => {
  const analyticsEvents = [
    'view_item_list',
    'select_item',
    'view_item',
    'add_to_cart',
    'remove_from_cart',
    'add_to_wishlist',
    'view_cart',
    'begin_checkout',
    'add_shipping_info',
    'add_payment_info',
    'purchase',
  ]

  return analyticsEvents.includes(name)
}
