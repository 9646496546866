import type { IconProps } from './IconsTypes'

export const VectorPlus = ({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7.565h12m-6 6v-12"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
