import type { IconProps } from './IconsTypes'

export const Ellipse = ({
  width = 16.02,
  height = 19,
  viewBox = '0 0 16 19',
  color = '#000',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m15.2 17.4-2.4-3"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx={8.295}
      cy={8.417}
      rx={6.905}
      ry={7.04}
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
