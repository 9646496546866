import { graphql, useStaticQuery } from 'gatsby'

import { SmartBannerItem } from './SmartBannerItem'

export const SmartBanner = () => {
  const data = useStaticQuery(graphql`
    query SmartBannerQuery {
      cmsHome {
        sections {
          data
          name
          id
        }
      }
    }
  `)

  const smartBanner = data?.cmsHome?.sections?.find(
    (el: any) => el.name === 'SmartBanner'
  )?.data.allSmartBanners

  if (!smartBanner) {
    return null
  }

  return (
    <>
      {smartBanner?.map((el: any, index: number) => (
        <div key={index}>
          {el.showOnDesktop && (
            <div className="hidden md:block">
              <SmartBannerItem
                key={`smart-banner-${index}`}
                type={el.type}
                backgroundColor={el.desktop.backgroundColor}
                componentPosition={el.desktop.componentPosition}
                closeIconColor={el.desktop.closeIconColor}
                imageSrc={el.desktop.imageSrc}
                imageAlt={el.desktop.imageAlt}
                title={el.desktop.title}
                text={el.desktop.text}
                textColor={el.desktop.textColor}
                buttonBackgroundColor={el.desktop.buttonBackgroundColor}
                buttonText={el.desktop.buttonText}
                buttonTextColor={el.desktop.buttonTextColor}
                link={el.desktop.link}
              />
            </div>
          )}

          {el.showOnMobile && (
            <div className="block md:hidden">
              <SmartBannerItem
                key={`smart-banner-${index}`}
                type={el.type}
                backgroundColor={el.mobile.backgroundColor}
                componentPosition={el.mobile.componentPosition}
                closeIconColor={el.mobile.closeIconColor}
                imageSrc={el.mobile.imageSrc}
                imageAlt={el.mobile.imageAlt}
                title={el.mobile.title}
                text={el.mobile.text}
                textColor={el.mobile.textColor}
                buttonBackgroundColor={el.mobile.buttonBackgroundColor}
                buttonText={el.mobile.buttonText}
                buttonTextColor={el.mobile.buttonTextColor}
                link={el.mobile.link}
              />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default SmartBanner
