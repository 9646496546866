import { MenuMobilPromocioneIcon } from 'src/components/icons/MenuMobilPromocioneIcon'
import { MenuMobileAcquista } from 'src/components/icons/MenuMobileAcquista'
import { MenuMobileAltri } from 'src/components/icons/MenuMobileAltri'
import { MenuMobileCaneCustom } from 'src/components/icons/MenuMobileCaneCustom'
import { MenuMobileCasa } from 'src/components/icons/MenuMobileCasa'
import { MenuMobileGattoIcon } from 'src/components/icons/MenuMobileGattoIcon'
import { MenuMobilePesciIcon } from 'src/components/icons/MenuMobilePesciIcon'
import { MenuMobilePiccoliIcon } from 'src/components/icons/MenuMobilePiccoliIcon'
import { MenuMobileRettili } from 'src/components/icons/MenuMobileRettili'
import { MenuMobileTartaruga } from 'src/components/icons/MenuMobileTartaruga'
import { MenuMobileUccelliIcon } from 'src/components/icons/MenuMobileUccelliIcon'
import { MenuMobileArcacard } from 'src/components/icons/MenuMobileArcacard'

export const getRenderMenuIcon = (icon?: string) => {
  switch (icon) {
    case 'menumobilepromocione':
      return <MenuMobilPromocioneIcon width={24} height={24} />

    case 'menumobilecane':
      return <MenuMobileCaneCustom width={24} height={24} />

    case 'menumobilegatto':
      return <MenuMobileGattoIcon width={24} height={24} />

    case 'menumobilepesci':
      return <MenuMobilePesciIcon width={24} height={24} />

    case 'menumobileuccelli':
      return <MenuMobileUccelliIcon width={24} height={24} />

    case 'menumobilepiccoli':
      return <MenuMobilePiccoliIcon width={24} height={24} />

    case 'piccolianimali':
      return <MenuMobilePiccoliIcon width={24} height={24} />

    case 'menumobileacquista':
      return <MenuMobileAcquista width={24} height={24} />

    case 'tartarughe':
      return <MenuMobileTartaruga width={24} height={24} />

    case 'rettili':
      return <MenuMobileRettili width={24} height={24} />

    case 'altrianimali':
      return <MenuMobileAltri width={24} height={24} />

    case 'casa':
      return <MenuMobileCasa width={24} height={24} />

    case 'arcacard':
      return <MenuMobileArcacard width={24} height={24} />

    default:
      return null
  }
}
