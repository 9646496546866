import { List } from '@faststore/ui'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import type { ReactNode } from 'react'
import './order-summary.scss'
import { useSession } from '@faststore/sdk'
import OrderSummaryLoyaltyPoints from './OrderSummaryLoyaltyPoints'

interface OrderSummaryProps {
  subTotal: number
  total: number
  numberOfItems: number
  checkoutButton?: ReactNode
  realDiscount: number
  loyaltyPoints: number
}

function OrderSummary({
  total,
  checkoutButton,
  realDiscount,
  loyaltyPoints
}: OrderSummaryProps) {
  // TODO: native code of vtex
  // const discount = subTotal - total
  const formattedDiscount = useFormattedPrice(realDiscount)
  const { person } = useSession()
  const loyaltyPointsText = person?.id ? <p>Con questo acquisto accumuli <b>{loyaltyPoints} Punti</b></p> : <p>Con questo acquisto avresti potuto accumulare <b>{loyaltyPoints} Punti</b></p>

  return (
    <>
      <List className="order-summary" data-order-summary>
        <li id="loyalty-points-id">
            <OrderSummaryLoyaltyPoints 
              loyaltyPointsText={loyaltyPointsText} 
            />
        </li>
        <li>
          <span>Subtotale</span>
          <span>{useFormattedPrice(total + realDiscount)}</span>
        </li>
        {realDiscount > 0 && (
          <li data-order-summary-discount>
            <span>Sconto</span>
            <span>{formattedDiscount}</span>
          </li>
        )}
        <li className="text__title-subsection">
          <span>Totale Ordine</span>
          <span>{useFormattedPrice(total)}</span>
        </li>
        {checkoutButton}
      </List>
    </>
  )
}

export default OrderSummary
