import type { IconProps } from './IconsTypes'

export const XCloseCustom = ({
  width = 24,
  height = 24,
  viewBox = '0 0 20 20',
  color = '#F8F8F8',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5L2.5 2.5M17.5 2.5L2.5 17.5"
      stroke="#14181F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
