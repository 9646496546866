import type { IconProps } from './IconsTypes'

export const MenuMobileAcquista = ({ width = 22, height = 22 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.0958 1.71387H20.7625L22.9793 22.4631C23.067 23.2839 22.4237 23.9996 21.5982 23.9996H3.26002C2.43457 23.9996 1.7913 23.2839 1.87899 22.4631L4.0958 1.71387Z"
      fill="#82C9C5"
    />
    <rect
      x="3.42969"
      y="0.856934"
      width="18"
      height="1.71429"
      rx="0.694444"
      fill="#FF8368"
    />
    <circle cx="12.3244" cy="13.1813" r="6.3244" fill="#F0ECE1" />
    <path
      d="M11.9997 11.1421C11.8575 11.3342 11.6046 11.4393 11.4154 11.3839C11.2595 11.3313 11.1184 11.2418 11.0043 11.1228C10.7161 10.8259 10.5665 10.3502 10.6142 9.88631C10.6212 9.81512 10.6326 9.74456 10.6486 9.67511C10.723 9.40714 10.9044 9.19221 11.1216 9.11309C11.142 9.10572 11.163 9.10041 11.1844 9.09716C11.2778 9.08297 11.3733 9.09097 11.4631 9.12054C11.8077 9.2508 12.0556 9.56895 12.1633 10.0166C12.1992 10.1632 12.2149 10.3139 12.2103 10.4646C12.203 10.7431 12.136 10.9593 11.9997 11.1421H11.9997Z"
      fill="#0F0F13"
    />
    <path
      d="M10.361 12.2541C10.2048 12.3972 10.0155 12.4376 9.80511 12.3725C9.63391 12.3209 9.47981 12.2319 9.35836 12.1132C9.15008 11.9152 9.01896 11.6634 8.98418 11.3947C8.96255 11.2384 8.97768 11.0799 9.02855 10.9293C9.09517 10.7372 9.28207 10.5979 9.50635 10.5716C9.51721 10.5703 9.52822 10.5696 9.53923 10.5694C9.60737 10.5684 9.67534 10.5755 9.74126 10.5901C9.86754 10.6215 10.0329 10.7206 10.173 10.8506C10.3799 11.0402 10.5128 11.2825 10.5532 11.5432C10.5677 11.6188 10.5713 11.6958 10.5637 11.7723C10.5476 12.0055 10.4941 12.1323 10.361 12.254L10.361 12.2541Z"
      fill="#0F0F13"
    />
    <path
      d="M12.8267 11.1421C12.9689 11.3342 13.2218 11.4393 13.411 11.3839C13.5668 11.3313 13.708 11.2418 13.8221 11.1228C14.1102 10.8259 14.2599 10.3502 14.2121 9.88631C14.2051 9.81512 14.1937 9.74456 14.1778 9.67511C14.1033 9.40714 13.9219 9.19221 13.7047 9.11309C13.6844 9.10572 13.6633 9.10041 13.6419 9.09716C13.5485 9.08297 13.453 9.09097 13.3632 9.12054C13.0186 9.2508 12.7707 9.56895 12.663 10.0166C12.6272 10.1632 12.6114 10.3139 12.616 10.4646C12.6233 10.7431 12.6903 10.9593 12.8266 11.1421H12.8267Z"
      fill="#0F0F13"
    />
    <path
      d="M14.4664 12.2541C14.6225 12.3972 14.8119 12.4376 15.0222 12.3725C15.1934 12.3209 15.3475 12.2319 15.469 12.1132C15.6773 11.9152 15.8084 11.6634 15.8432 11.3947C15.8648 11.2384 15.8497 11.0799 15.7988 10.9293C15.7322 10.7372 15.5453 10.5979 15.321 10.5716C15.3101 10.5703 15.2991 10.5696 15.2881 10.5694C15.22 10.5684 15.152 10.5755 15.0861 10.5901C14.9598 10.6215 14.7945 10.7206 14.6543 10.8506C14.4474 11.0402 14.3145 11.2825 14.2741 11.5432C14.2597 11.6188 14.256 11.6958 14.2636 11.7723C14.2797 12.0055 14.3332 12.1323 14.4663 12.254L14.4664 12.2541Z"
      fill="#0F0F13"
    />
    <path
      d="M14.4381 13.2245C13.9191 12.5481 13.3032 12.0021 12.4134 11.9966C11.5234 12.0021 10.9075 12.5481 10.3885 13.2245C9.96942 13.7709 9.66449 14.4684 9.72177 15.1696C9.8692 16.9777 11.7681 16.4611 11.7681 16.4611C11.9389 16.4089 12.0981 16.35 12.246 16.2855C12.253 16.2828 12.4226 16.2205 12.5806 16.2855C12.7286 16.35 12.8877 16.4089 13.0584 16.4611C13.0584 16.4611 14.9574 16.9775 15.1049 15.1696C15.1619 14.4684 14.8572 13.7709 14.4381 13.2245Z"
      fill="#0F0F13"
    />
  </svg>
)
