import type { LinkProps } from '@faststore/ui'
import { Link as UILink } from '@faststore/ui'
import { Link as GatsbyLink } from 'gatsby'
import type { ElementType } from 'react'
import './link.scss'

type Variant = 'default' | 'display' | 'inline' | 'footer'

type Props<T extends ElementType = typeof GatsbyLink> = LinkProps<T> & {
  variant?: Variant
  inverse?: boolean
}

function Link<T extends ElementType = typeof GatsbyLink>({
  variant = 'default',
  inverse,
  to,
  as,
  ...props
}: Props<T>) {
  return (
    <>
      {typeof to !== 'undefined' &&
      typeof to === 'string' &&
      to.startsWith('/') ? (
        <UILink
          as={GatsbyLink}
          data-fs-link
          data-fs-link-variant={variant}
          data-fs-link-inverse={inverse}
          to={to}
          {...props}
        />
      ) : (
        <UILink
          data-fs-link
          data-fs-link-variant={variant}
          data-fs-link-inverse={inverse}
          href={to}
          as={as}
          {...(props as LinkProps<T>)}
        />
      )}
    </>
  )
}

export default Link
