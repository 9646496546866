import { List as UIList, Icon as UIIcon } from '@faststore/ui'
import Link from 'src/components/ui/Link'

import { socialIcons } from './FooterData'
import './footer.scss'

function SocialNetwork() {
  return (
    <section className="footer__social">
      <p className="text__title-social">Seguici su:</p>
      <UIList variant="unordered">
        <div className="footer__social-icon">
          {socialIcons.map((socialIcon, index) => (
            <li key={index}>
              <Link
                as="a"
                href={socialIcon.href}
                title={socialIcon.title}
                target="_blank"
                rel="noopener noreferrer"
              >
                <UIIcon component={socialIcon.iconName} />
              </Link>
            </li>
          ))}
        </div>
      </UIList>
    </section>
  )
}

export default SocialNetwork
