import type { IconProps } from './IconsTypes'

export const MenuMobileUccelliIcon = ({
  width = 20,
  height = 17,
  viewBox = '0 0 20 17',
  color = '#000',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.399 4.4a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Z" fill={color} />
    <path
      d="M12.67 1.87 1.6 12.67h10.606c2.07 0 3.907-1.308 4.759-3.172.786-1.734.496-3.798.496-5.648a2.807 2.807 0 0 0-2.805-2.805 2.807 2.807 0 0 0-2.805 2.805s.568 8.13-5.919 8.827"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m11.851 3.76.007-1.187L1.6 12.58h4.339c6.48-.696 5.912-8.82 5.912-8.82Z"
      fill="#000"
    />
    <path
      d="m8.607 12.67 2.747 2.747h1.98M17.1 2.47 18.763.8"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
