export const ArcaplanetDisabledMapLocationPin = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8473 4.2207C12.0953 4.2207 5.7888 10.6592 5.7888 18.5734C5.7888 26.9875 13.4449 32.151 18.5109 35.5669L19.3875 36.1614C19.5265 36.256 19.6869 36.3032 19.8457 36.3032C20.0044 36.3032 20.1649 36.256 20.3038 36.1614L21.1804 35.5669C26.2464 32.151 33.9025 26.9875 33.9025 18.5734C33.9059 10.6592 27.5994 4.2207 19.8473 4.2207ZM19.8473 22.7948C17.5632 22.7948 15.7125 20.9053 15.7125 18.5734C15.7125 16.2415 17.5632 14.352 19.8473 14.352C22.1314 14.352 23.9822 16.2415 23.9822 18.5734C23.9822 20.9053 22.1314 22.7948 19.8473 22.7948Z"
        fill="#A9A9B7"
      />
    </svg>
  )
}
