import type { IconProps } from './IconsTypes'

export const HamburgerMenu = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  color = '#14181F',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 19h18M3 12h18M3 5h18"
      stroke={color}
      strokeOpacity={0.92}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
