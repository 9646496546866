import type { IconProps } from './IconsTypes'

export const AgilityIcon = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  color = '#FFF',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.874 4.679v.75-.75Zm-6.415 0v-.75.75Zm6.415.75h1.793v-1.5h-1.793v1.5Zm1.793 0c.046 0 .083.037.083.083h1.5c0-.874-.709-1.583-1.583-1.583v1.5Zm.083.083v1.793h1.5V5.512h-1.5Zm-.083 1.75a3.25 3.25 0 0 0-3.25 3.25h1.5c0-.966.783-1.75 1.75-1.75v-1.5Zm-3.25 3.25a3.25 3.25 0 0 0 3.25 3.25v-1.5a1.75 1.75 0 0 1-1.75-1.75h-1.5Zm3.333 3.208v1.792h1.5V13.72h-1.5Zm0 1.792a.083.083 0 0 1-.083.084v1.5c.874 0 1.583-.71 1.583-1.584h-1.5Zm-.083.084h-10v1.5h10v-1.5Zm-10 0a.083.083 0 0 1-.084-.084h-1.5c0 .875.71 1.584 1.584 1.584v-1.5Zm-.084-.084V13.72h-1.5v1.792h1.5ZM4 12.262a1.75 1.75 0 0 1-1.75-1.75H.75A3.25 3.25 0 0 0 4 13.762v-1.5Zm-1.75-1.75c0-.966.784-1.75 1.75-1.75v-1.5a3.25 3.25 0 0 0-3.25 3.25h1.5Zm3.333-3.207V5.512h-1.5v1.793h1.5Zm0-1.793c0-.046.038-.083.084-.083v-1.5c-.875 0-1.584.709-1.584 1.583h1.5Zm.084-.083h1.792v-1.5H5.667v1.5Zm3.25-1.583c0-.967.783-1.75 1.75-1.75v-1.5a3.25 3.25 0 0 0-3.25 3.25h1.5Zm1.75-1.75c.966 0 1.75.783 1.75 1.75h1.5a3.25 3.25 0 0 0-3.25-3.25v1.5ZM7.459 5.429a1.4 1.4 0 0 0 1.096-.543c.232-.292.362-.665.362-1.04h-1.5a.184.184 0 0 1-.035.104c-.006.008-.005.003.01-.005a.149.149 0 0 1 .067-.016v1.5ZM4 8.762c.375 0 .749-.129 1.04-.362a1.4 1.4 0 0 0 .543-1.095h-1.5c0-.026.008-.05.017-.067.007-.015.012-.016.004-.01A.184.184 0 0 1 4 7.262v1.5Zm1.583 4.958a1.4 1.4 0 0 0-.543-1.096A1.682 1.682 0 0 0 4 12.262v1.5a.185.185 0 0 1 .104.035c.008.006.003.005-.004-.01a.149.149 0 0 1-.017-.067h1.5Zm10.084.042a.184.184 0 0 1 .104.035c.008.006.003.005-.005-.01a.148.148 0 0 1-.016-.067h1.5a1.4 1.4 0 0 0-.543-1.096 1.682 1.682 0 0 0-1.04-.362v1.5Zm.083-6.457c0-.026.007-.05.016-.067.008-.015.013-.016.005-.01a.184.184 0 0 1-.104.034v1.5c.374 0 .748-.129 1.04-.362a1.4 1.4 0 0 0 .543-1.095h-1.5ZM13.874 3.93c.026 0 .05.007.067.016.015.008.016.013.01.005a.185.185 0 0 1-.034-.104h-1.5c0 .375.129.748.362 1.04a1.4 1.4 0 0 0 1.095.543v-1.5Z"
      fill={color}
    />
  </svg>
)
