/* eslint-disable @typescript-eslint/no-explicit-any */
import { CartProvider, SessionProvider, UIProvider } from '@faststore/sdk'
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js'
import type { GatsbyBrowser } from 'gatsby'
import Layout from 'src/Layout'
import LayoutFullscreen from 'src/LayoutFullscreen'
import { RenderClient } from 'src/components/RenderClient'
import ClerkioScript from 'src/components/ThirdPartyScripts/ClerkioScript'
import SharedCartModal from 'src/components/cart/SharedCartModal'
import { SharedCartProvider } from 'src/contexts/shared-cart-context'
import { WishlistProvider } from 'src/contexts/wishlist-context'
import AnalyticsHandler from 'src/sdk/analytics'
import { validateSharedCart } from 'src/sdk/cart/validate'
import ErrorBoundary from 'src/sdk/error/ErrorBoundary'
import { validateSession } from 'src/sdk/session/validate'
import { uiActions, uiEffects, uiInitialState } from 'src/sdk/ui'
import { ModalProvider } from 'src/sdk/ui/modal'
import './src/styles/global.css'
import './src/styles/global/layout.scss'
import './src/styles/global/resets.scss'
import './src/styles/global/tokens.scss'
import './src/styles/global/typography.scss'

import { Helmet } from 'react-helmet'
import { StoreProvider } from 'src/contexts/store-context'
import { UserAccountProvider } from 'src/contexts/user-account-context'
import { reactPlugin } from './src/sdk/insights/ApplicationInsightsService'
import storeConfig from './store.config'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <AppInsightsErrorBoundary
    onError={() => <h1>I believe something went wrong</h1>}
    appInsights={reactPlugin}
  >
    <AppInsightsContext.Provider value={reactPlugin}>
      <ErrorBoundary>
        <AnalyticsHandler />
        <UIProvider
          initialState={uiInitialState}
          actions={uiActions}
          effects={uiEffects}
        >
          <SessionProvider
            initialState={{
              channel: storeConfig.session.channel,
              locale: storeConfig.session.locale,
              currency: storeConfig.session.currency,
              country: storeConfig.session.country,
            }}
            onValidateSession={validateSession}
          >
            <CartProvider mode="optimistic" onValidateCart={validateSharedCart}>
              <SharedCartProvider>
                <WishlistProvider>
                  <ModalProvider>
                    <StoreProvider>
                      <UserAccountProvider>
                        {element}
                        <RenderClient>
                          <SharedCartModal />
                        </RenderClient>
                      </UserAccountProvider>
                    </StoreProvider>
                  </ModalProvider>
                </WishlistProvider>
              </SharedCartProvider>
            </CartProvider>
          </SessionProvider>
        </UIProvider>
      </ErrorBoundary>
    </AppInsightsContext.Provider>
  </AppInsightsErrorBoundary>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const node = props?.data?.cmsFlyerMobile

  if (node) {
    // @ts-expect-error config throw error
    if (node?.config?.slugAndFilterConfig?.noLayout) {
      return <>{element}</>
    }
  }
  const nodeFull = props?.data?.cmsFlyerFullScreen

  if (nodeFull) {
    // @ts-expect-error config throw error
    if (nodeFull?.config?.slugAndFilterConfig?.noLayout) {
      return <LayoutFullscreen>{element}</LayoutFullscreen>
    }
  }

  const nodeBooxieMobilePage = props?.data?.cmsBooxieMobilePage
  if (nodeBooxieMobilePage) {
    const externalScriptUrls: string[] =
      // @ts-expect-error config throw error
      nodeBooxieMobilePage.config?.slugAndFilterConfig?.externalScripts ?? []
    // @ts-expect-error config throw error
    if (nodeBooxieMobilePage?.config?.slugAndFilterConfig?.noLayout) {
      return (
        <>
          {externalScriptUrls.length &&
            externalScriptUrls.map((scriptSrc: string) => (
              <Helmet>
                <script src={scriptSrc}></script>
              </Helmet>
            ))}
          {element}
        </>
      )
    } else {
      return (
        <>
          {externalScriptUrls.length &&
            externalScriptUrls.map((scriptSrc: string) => (
              <Helmet>
                <script src={scriptSrc}></script>
              </Helmet>
            ))}
          <Layout>{element}</Layout>
        </>
      )
    }
  }

  return (
    <Layout>
      {element}
      <RenderClient>
        <ClerkioScript />
      </RenderClient>
    </Layout>
  )
}

export const onInitialClientRender = () => {
  // Signal that the app is ready for Cypress tests
  document.body.dataset.testReady = 'true'
}
