/* eslint-disable react/no-unknown-property */
// import { ArrowRightMini } from 'src/components/icons/ArrowRightMini'
import './menu.scss'

import { mockMenu2 } from './mock2'
import { mockImage } from './mockImage'
import { mockImageMenu } from './mockImageMenu'
import { mockMenuCane } from './mockMenuCane'
import { mockMenuGatto } from './mockMenuGatto'

type MenuProps = {
  item: MenuItemProps
  parent?: string
}

type MockImageProps = {
  src: string
  alt: string
  url?: string
}

type MenuItemProps = {
  id: number
  url: string
  name: string
  code?: string
  isVisible?: boolean
  styles?: Record<string, string>
  children: MenuItemProps[]
  component?: JSX.Element
  links?: Array<{
    url: string
    name: string
  }>
}

function MenuItem({ item }: MenuProps) {
  return (
    <div className="container-fashion-ui-mega-menu-column">
      <div
        fashion-ui-mega-menu-column="true"
        key={`${item.name}-id-${item.id}`}
      >
        {/* <a
          className={!item.url ? 'cursorpointernone' : ''}
          fashion-ui-mega-menu-title="true"
          href={item.url}
        >
          {item.name}
        </a> */}
        {item.children.map((level3, idx) => {
          return (
            <ul key={`${level3.name}-idx-${idx}`}>
              <li style={{ ...level3.styles }}>
                <a
                  className={!level3.url ? 'cursorpointernone' : ''}
                  href={level3.url}
                >
                  {level3.name}
                </a>
                {level3.children.length ? (
                  <ul key={`${level3.name}-level3-${idx}`}>
                    <li>
                      {level3.children.map((level4) => {
                        return (
                          <ul key={`${level4.name}-level4-${level4.id}`}>
                            <li style={{ ...level3.styles }}>
                              <a href={level4.url}>{level4.name}</a>
                            </li>
                          </ul>
                        )
                      })}
                    </li>
                  </ul>
                ) : null}
              </li>
            </ul>
          )
        })}
        {/* {item.children.length && parent !== 'Arcacard' ? (
          <a data-fashion-ui-mega-menu-link="true" href={item.url}>
            Visualizza tutto &nbsp;
            <ArrowRightMini
              width={5}
              height={8}
              color="currentColor"
              viewBox="0 0 5 8"
            />
          </a>
        ) : null} */}
      </div>
    </div>
  )
}

function Menu() {
  const menus = [...mockMenuCane, ...mockMenuGatto, ...mockMenu2]
  const topBrandCategory = (name: string) => {
    return name === 'Cane'
      ? 'per Cani'
      : name === 'Gatto'
      ? 'per Gatti'
      : name === 'Piccoli Animali'
      ? 'per Piccoli Animali'
      : ''
  }

  return (
    <>
      <header data-fashion-ui-menu="true" fashion-ui-menu="true">
        <nav data-fashion-ui-menu-nav="true" fashion-ui-menu-nav="true">
          <ul data-fashion-ui-menu-ul="true" fashion-ui-menu-ul="true">
            {menus.map((level1, idx) => {
              return level1.children.length ? (
                <li
                  key={`${level1?.name}-level1-${idx}`}
                  data-fashion-ui-menu-li="true"
                  fashion-ui-menu-li="true"
                >
                  <a
                    href={level1.url}
                    className={menus.length - 1 === idx ? 'last-item' : ''}
                  >
                    {level1.name}
                    {level1.image}
                  </a>
                  <div
                    data-fashion-ui-mega-menu="true"
                    fashion-ui-mega-menu="true"
                  >
                    <div
                      data-fashion-ui-mega-menu-wrap="true"
                      fashion-ui-mega-menu-wrap="true"
                    >
                      <div
                        data-fashion-ui-mega-menu-between="true"
                        fashion-ui-mega-menu-between="true"
                      >
                        <section
                          data-fashion-ui-mega-menu-flex="true"
                          fashion-ui-mega-menu-flex="true"
                          className={`fashion-ui-megamenu-wrapper fashion-ui-megamenu-wrapper-${level1.name.toLowerCase()}`}
                          data-category-name={`${level1.name.toLowerCase()}`}
                        >
                          <div
                            className={`menu-category-grid menu-category-grid-${level1.name.toLowerCase()}`}
                          >
                            {level1.children.map((level2, idx2) => (
                              <MenuItem
                                key={`${level1?.name}-menu-item-${idx2}`}
                                item={level2}
                                parent={level1?.name}
                              />
                            ))}
                          </div>
                          {level1.name !== 'Arcacard' ? (
                            <>
                              <div className="image-brand-seccion">
                                <ul className="image-brand">
                                  {level1.name === 'Rettili' ? (
                                    <span className="image-brand__title" />
                                  ) : (
                                    <span className="image-brand__title">
                                      Top Brand {topBrandCategory(level1.name)}
                                    </span>
                                  )}
                                  {mockImage[
                                    level1.code as keyof typeof mockImage
                                  ] &&
                                    mockImage[
                                      level1.code as keyof typeof mockImage
                                    ].map(
                                      (
                                        { src, alt, url }: MockImageProps,
                                        index
                                      ) => (
                                        <li
                                          key={`index-${index}`}
                                          className={
                                            !url ? 'cursorpointernone' : ''
                                          }
                                        >
                                          <a href={url}>
                                            <img
                                              className="image-brand-image"
                                              src={`/imagesMenuDesktop_webp/brands/${src}`}
                                              alt={alt}
                                              width="90"
                                              height="90"
                                              loading="lazy"
                                            />
                                          </a>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                              <div className="image-category">
                                {mockImageMenu[
                                  level1.code as keyof typeof mockImageMenu
                                ] &&
                                  mockImageMenu[
                                    level1.code as keyof typeof mockImageMenu
                                  ].map(
                                    ({ src, alt }: MockImageProps, index) => (
                                      <img
                                        className="image-category-image"
                                        key={`image-${index}`}
                                        src={`/imagesMenuDesktop_webp/${src}`}
                                        alt={alt}
                                        loading="lazy"
                                      />
                                    )
                                  )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </section>
                        <div
                          data-fashion-ui-mega-menu-component="true"
                          fashion-ui-mega-menu-component="true"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <li data-fashion-ui-menu-li="true" key={`li-${idx}`}>
                  <a href={level1.url}>{level1.name}</a>
                </li>
              )
            })}
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Menu
