/* eslint-disable react-hooks/exhaustive-deps */
import { useSession } from '@faststore/sdk'
import axios from 'axios'
import type { FC } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'

const WishlistContext = createContext<WishlistContextProps>(
  {} as WishlistContextProps
)

export const WishlistProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [list, setList] = useState<Record<string, WishlistProduct>>({})

  const { person } = useSession()

  const getList = async () => {
    if (!person?.id) {
      return
    }

    const { data } = await axios.get('/api/wishlist/getWishlist', {
      params: {
        shopperId: person?.email,
      },
    })

    const tmpList: Record<string, WishlistProduct> = {}

    data?.data?.viewLists[0]?.data?.forEach((item: WishlistProduct) => {
      tmpList[item.sku] = item
    })

    setList(tmpList)
  }

  useEffect(() => {
    getList()
  }, [person])

  const addProductToWishlist = async (item: {
    productId: string
    sku: string
    title: string
  }): Promise<boolean> => {
    const response = await axios.post('/api/wishlist/addProduct', {
      shopperId: person?.email,
      listItem: item,
    })

    if (response.status !== 200) {
      return false
    }

    const tmpItem = { ...item, id: response.data?.data?.addToList }

    setList((prev) => ({ ...prev, [tmpItem.sku]: tmpItem }))

    return true
  }

  const removeProductFromWishlist = async (sku: string) => {
    const product = list[sku]

    if (!product) {
      return false
    }

    const response = await axios.delete('/api/wishlist/removeProduct', {
      data: {
        shopperId: person?.email,
        itemId: product.id,
      },
    })

    if (response.status !== 200) {
      return false
    }

    const wasRemoved = response.data?.data?.removeFromList

    if (!wasRemoved) {
      return false
    }

    setList((prev) => {
      const { [sku]: _, ...rest } = prev

      return rest
    })

    return true
  }

  return (
    <WishlistContext.Provider
      value={{
        list,
        getList,
        addProductToWishlist,
        removeProductFromWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}

export const useWishlistContext = () => {
  return useContext(WishlistContext)
}
