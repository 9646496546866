/* eslint-disable @typescript-eslint/no-explicit-any */
export const getRealDiscount = (items: any) => {
  let result = 0

  items.forEach((item: any) => {
    const itemSku = item.itemOffered.sku
    const itemVariantFiltered = item.itemOffered.isVariantOf.hasVariant.filter(
      (i: any) => i.sku === itemSku
    )

    const itemLowPrice = itemVariantFiltered[0]?.offers?.lowPrice

    let unitMultiplier = 1

    if (item.price !== itemLowPrice) {
      unitMultiplier = item.price / itemLowPrice
    }

    const realQuantity = item.quantity * unitMultiplier

    result += item.listPrice * realQuantity - itemLowPrice * realQuantity
  })

  return result
}
