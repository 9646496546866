import { Fragment, useState } from 'react'
import { ArrowRightMenuCustom } from 'src/components/icons/ArrowRightMenuCustom'
import { ArrowleftIcons } from 'src/components/icons/ArrowleftIcons'
import { XCloseCustom } from 'src/components/icons/XCloseCustom'
import Link from 'src/components/ui/Link'
import SlideOver from 'src/components/ui/SlideOver'
import { useModal } from 'src/sdk/ui/modal/Provider'

import type { ImageMenuProps, ItemMenuProps } from '../typings/menu'
import './secondmodal.scss'

interface Props {
  closeModal: (a: boolean) => void
  submenu: ItemMenuProps[]
  submenuImages: ImageMenuProps[]
  categoryName: string
}
interface PropsFirstmodal {
  index: number
  title?: string
  iconName?: string
  link?: string
}

const MenuLevelX = ({
  submenu,
  closeModal,
  categoryName,
  submenuImages: submenuimages,
}: Props) => {
  const { onModalClose } = useModal()

  const [showMenu, setShowMenu] = useState(false)
  const [secondCategoy, setSecondCategoy] = useState({
    index: 0,
    title: '',
  })

  const handleCloseSlideOver = () => {
    onModalClose()
    setShowMenu(false)
  }

  const handleClick = ({
    index,
    title = '',
    iconName = '',
    link = '',
  }: PropsFirstmodal) => {
    setSecondCategoy({ index, title })
    //! TODO: por que esta validación?
    iconName === '' ? onModalClose() : setShowMenu(true)

    if (!submenu[index].items) {
      handleCloseSlideOver()
      window.location.href = link
    }
  }

  const closeModalSecondary = () => {
    setShowMenu(false)
  }

  if (showMenu && secondCategoy.title) {
    const submenux = submenu[secondCategoy.index].items as ItemMenuProps[]

    return (
      submenux && (
        <SlideOver
          isOpen={showMenu}
          onDismiss={handleCloseSlideOver}
          size="full"
          direction="leftSide"
          className="modal__mobile"
        >
          <MenuLevelX
            submenu={submenux}
            submenuImages={[]}
            categoryName={secondCategoy.title}
            closeModal={closeModalSecondary}
          />
        </SlideOver>
      )
    )
  }

  return (
    <Fragment>
      <div className="secondarymodal__header">
        <div className="secondarymodal__header--content">
          <div className="secondarymodal__header--icontitle">
            <button
              className="mainmodal__nav--button svg.arrow-left width-btn-arrow-left"
              onClick={() => {
                closeModal(false)
              }}
              style={{ width: 'fit-content' }}
            >
              <ArrowleftIcons width={20} height={20} />
            </button>
            <span>{categoryName}</span>
          </div>

          <div
            className="menu-close-modal-container"
            onClick={() => {
              handleCloseSlideOver()
            }}
            aria-hidden="true"
          >
            <XCloseCustom />
          </div>
        </div>
      </div>
      <Fragment>
        {submenu?.map(
          (
            { title, link, iconName, subtitle, items }: ItemMenuProps,
            index
          ) => (
            <div className="mainmodal__nav--wrapper" key={index}>
              <div className="mainmodal__nav--button" data-mainmodal={title}>
                <Fragment key={index}>
                  <button
                    className="mainmodal__nav--iconlink"
                    onClick={() =>
                      handleClick({ index, title, iconName, link })
                    }
                  >
                    {/* {iconName?.length ? (
                      <img
                        src={`/menuMobileImage/${iconName}`}
                        alt={iconName}
                        width={24}
                        height={24}
                      />
                    ) : null} */}
                    <button
                      onClick={() => handleCloseSlideOver()}
                      className="mainmodal__nav--title"
                    >
                      {title}
                      <span className="subtitle">{subtitle}</span>
                    </button>
                  </button>
                  <button
                    key={index}
                    className="mainmodal__nav--button-arrow"
                    onClick={() =>
                      handleClick({ index, title, iconName, link })
                    }
                  >
                    {((items && items.length > 0) ||
                      title.includes('Visualizza')) && (
                      <div className="arrowRightModal-container">
                        <ArrowRightMenuCustom />
                      </div>
                    )}
                  </button>
                </Fragment>
              </div>
              <hr className="mainmodal__nav--hr" />
            </div>
          )
        )}
      </Fragment>
      <div className="secondarymodal__footer">
        {submenuimages.map(({ img, alt, url }, index) => (
          <Link
            className="secondarymodal__footer--url"
            as="a"
            href={url}
            key={index}
            onClick={() => handleCloseSlideOver()}
          >
            <img
              className="secondarymodal__footer--img"
              key={index}
              src={`/menuMobileImage/${img}`}
              alt={alt}
            />
          </Link>
        ))}
      </div>
    </Fragment>
  )
}

export default MenuLevelX
