import './mainmenu.scss'
import { ButtonLink } from 'src/components/ui/Button'
import { Link as GatsbyLink } from 'gatsby'
import { useSession } from '@faststore/sdk'
import { ArcaplanetMobileLogo } from 'src/components/icons/ArcaplanetMobileIcon'

import storeConfig from '../../../../../store.config'

const HeaderMainModal = () => {
  const { person } = useSession()

  const returnUrl = encodeURIComponent(
    `${storeConfig.storeUrl}${window.location.pathname}`
  )

  return (
    <div className="mainmodal__header">
      <span className="mainmodal__header--iconandbutton">
        <GatsbyLink
          to="/"
          aria-label="Go to Arcaplanet homepage"
          className="mainmodal__header--logo"
        >
          <ArcaplanetMobileLogo width={32} height={32} viewBox="0 0 24 22" />
        </GatsbyLink>
      </span>
      {!person?.id ? (
        <>
          <ButtonLink to={`${storeConfig.loginUrl}/?returnUrl=${returnUrl}`}>
            Accedi
          </ButtonLink>
          <a
            className="mainmodal__header-link"
            href={`${storeConfig.loginUrl}/?returnUrl=${returnUrl}`}
          >
            Registrati Ora!
          </a>
        </>
      ) : null}
    </div>
  )
}

export default HeaderMainModal
