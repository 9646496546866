import type { IconProps } from './IconsTypes'

export const FacebookIcon = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  color = '#fff',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 15.036c0 3.64-2.17 5.81-5.81 5.81H13c-.55 0-1-.45-1-1v-5.77c0-.27.22-.5.49-.5l1.76-.03c.14-.01.26-.11.29-.25l.35-1.91a.303.303 0 0 0-.3-.35l-2.13.03c-.28 0-.5-.22-.51-.49l-.04-2.45c0-.16.13-.3.3-.3l2.4-.04c.17 0 .3-.13.3-.3l-.04-2.4c0-.17-.13-.3-.3-.3l-2.7.04a2.996 2.996 0 0 0-2.95 3.05l.05 2.75c.01.28-.21.5-.49.51l-1.2.02c-.17 0-.3.13-.3.3l.03 1.9c0 .17.13.3.3.3l1.2-.02c.28 0 .5.22.51.49l.09 5.7c.01.56-.44 1.02-1 1.02h-2.3c-3.64 0-5.81-2.17-5.81-5.82v-8.37C0 3.016 2.17.846 5.81.846h8.38c3.64 0 5.81 2.17 5.81 5.81v8.38Z"
        fill={color}
      />
    </svg>
  )
}
