import type { IconProps } from './IconsTypes'

export const LinkedinIcon = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  color = '#fff',
}: IconProps) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path d="M21.125 0H4.875A4.876 4.876 0 0 0 0 4.875v16.25A4.876 4.876 0 0 0 4.875 26h16.25A4.876 4.876 0 0 0 26 21.125V4.875A4.876 4.876 0 0 0 21.125 0ZM8.039 22.07H4L3.977 9.977h4.039ZM5.918 8.395h-.023c-1.32 0-2.172-.91-2.172-2.043 0-1.16.879-2.04 2.222-2.04 1.344 0 2.168.88 2.196 2.04 0 1.132-.856 2.043-2.223 2.043ZM22.043 22.07h-4.074V15.5c0-1.59-.422-2.672-1.844-2.672-1.086 0-1.672.73-1.953 1.438-.106.254-.133.601-.133.957v6.847H9.945L9.922 9.977h4.094l.023 1.707c.524-.809 1.395-1.954 3.48-1.954 2.586 0 4.52 1.688 4.52 5.317v7.023Z" />
    </svg>
  )
}
