import { ButtonLink } from 'src/components/ui/Button'
import EmptyState from 'src/components/ui/EmptyState'

import storeConfig from '../../../../store.config'
import empty_background from '../../../../static/Cart/empty_background.png'

import './empty-cart.scss'

interface Props {
  /**
   * This function is called when `Start Shopping` button is clicked
   */
  onDismiss: () => void
}

function EmptyCart({ onDismiss }: Props) {
  return (
    <EmptyState>
      <div className="imageandtitle">
        <img src={empty_background} alt="" />
        <p>Il tuo carrello è vuoto</p>
      </div>
      <footer>
        <ButtonLink data-fs-button="true" to="/">
          Inizia lo shopping
        </ButtonLink>
        <ButtonLink
          data-fs-button="true"
          onClick={onDismiss}
          to={`${storeConfig.accountUrl}#/wishlist`}
        >
          Visualizza la Wishlist
        </ButtonLink>
      </footer>
    </EmptyState>
  )
}

export default EmptyCart
