import { useState } from 'react'

type SmartBannerProps = {
  type: string
  backgroundColor: string
  componentPosition: string
  closeIconColor: string
  imageSrc: string
  imageAlt: string
  title: string
  text: string
  textColor: string
  buttonBackgroundColor: string
  buttonText: string
  buttonTextColor: string
  link: string
}

export const SmartBannerItem = (props: SmartBannerProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const [show, setShow] = useState(true)

  const handleDownloadClick = (link: string) => {
    window.location.href = link
  }

  const handleCouponClick = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code)
      setIsCopied(true)
    } catch (err) {
      null
    }
  }

  return (
    <div
      className={`text-white p-3.5 flex items-center justify-between ${
        props.componentPosition === 'bottom' ? 'fixed bottom-0 w-full z-50' : ''
      } ${show ? 'block' : 'hidden'}`}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className="flex items-center gap-1">
        <button
          onClick={() => setShow(false)}
          className="cursor-pointer bg-white md:hidden pl-0"
        >
          <svg
            className="w-4 h-4"
            color={props.closeIconColor}
            strokeWidth="16"
          >
            <use href="/icons.svg#X" width="100%" height="100%" />
          </svg>
        </button>
        <img width="30px" src={props.imageSrc} alt={props.imageAlt} />
        <div className="flex flex-col px-1 gap-1">
          <p
            className="text-[12px] md:text-sm font-semibold"
            style={{ color: props.textColor }}
          >
            {props.title}
          </p>
          <p
            className="text-[10px] md:text-2sm"
            style={{ color: props.textColor }}
          >
            {props.text}
          </p>
        </div>
      </div>
      <button
        className={`text-xxs w-fit px-2 py-2 rounded-md ${
          props.type === 'download' ? 'whitespace-nowrap' : ''
        } ml-2 cursor-pointer`}
        disabled={isCopied}
        style={{
          backgroundColor: props.buttonBackgroundColor,
          color: props.buttonTextColor,
        }}
        onClick={() =>
          props.type === 'download'
            ? handleDownloadClick(props.link)
            : handleCouponClick(props.link)
        }
      >
        {isCopied ? 'COPIATO' : props.buttonText}
      </button>
    </div>
  )
}
