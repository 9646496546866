import type { IconProps } from './IconsTypes'

export const MenuMobileCaneCustom = ({
  width = 18,
  height = 18,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.60156 5.11811C1.60156 4.19673 1.96758 3.31309 2.61909 2.66157C3.27061 2.01006 4.15425 1.64404 5.07563 1.64404H18.9719C19.8933 1.64404 20.7769 2.01006 21.4284 2.66157C22.08 3.31309 22.446 4.19673 22.446 5.11811V17.2774C22.446 18.1987 22.08 19.0824 21.4284 19.7339C20.7769 20.3854 19.8933 20.7514 18.9719 20.7514H5.07563C4.15425 20.7514 3.27061 20.3854 2.61909 19.7339C1.96758 19.0824 1.60156 18.1987 1.60156 17.2774V5.11811Z"
      fill="#F3C07B"
    />
    <path
      d="M19.0272 16.2729C19.0272 12.3863 15.2813 11.1704 12.079 11.1704C8.49639 11.1704 5.07617 12.5262 5.07617 16.2729C5.07617 19.7036 8.82209 22.6505 12.079 22.6505C15.9874 22.6505 19.0272 18.6067 19.0272 16.2729Z"
      fill="#FFDEA7"
    />
    <path
      d="M9.41211 18.7159L12.0246 16.7617L14.6371 18.7159V20.7517C14.6371 22.1908 13.4637 23.3572 12.0246 23.3572C11.3329 23.3575 10.6693 23.0833 10.1795 22.5948C9.6897 22.1063 9.41372 21.4434 9.41211 20.7517V18.7159Z"
      fill="#FF8368"
    />
    <path
      d="M8.31171 2.57052L1.97153 11.5119C1.35923 12.3752 0 11.9418 0 10.884V4.76526C0 3.72871 0.411769 2.73461 1.14472 2.00166C1.87768 1.2687 2.87178 0.856934 3.90833 0.856934H7.42582C7.625 0.856884 7.82035 0.911629 7.99049 1.01518C8.16064 1.11872 8.29902 1.26708 8.39048 1.44401C8.48195 1.62094 8.52299 1.81962 8.50909 2.01831C8.4952 2.217 8.42691 2.40804 8.31171 2.57052ZM15.7089 2.57052L22.0491 11.5119C22.6605 12.3752 24.0206 11.9418 24.0206 10.884V4.76526C24.0206 3.72871 23.6088 2.73461 22.8759 2.00166C22.1429 1.2687 21.1488 0.856934 20.1123 0.856934H16.5948C16.3956 0.856884 16.2002 0.911629 16.0301 1.01518C15.8599 1.11872 15.7216 1.26708 15.6301 1.44401C15.5386 1.62094 15.4976 1.81962 15.5115 2.01831C15.5254 2.217 15.5937 2.40804 15.7089 2.57052Z"
      fill="#955A42"
    />
    <path
      d="M8.54883 8.59215C8.54883 8.36181 8.64033 8.14089 8.80321 7.97802C8.96609 7.81514 9.187 7.72363 9.41735 7.72363C9.64769 7.72363 9.8686 7.81514 10.0315 7.97802C10.1944 8.14089 10.2859 8.36181 10.2859 8.59215V9.46067C10.2859 9.69101 10.1944 9.91192 10.0315 10.0748C9.8686 10.2377 9.64769 10.3292 9.41735 10.3292C9.187 10.3292 8.96609 10.2377 8.80321 10.0748C8.64033 9.91192 8.54883 9.69101 8.54883 9.46067V8.59215ZM13.7599 8.59215C13.7599 8.36181 13.8514 8.14089 14.0143 7.97802C14.1772 7.81514 14.3981 7.72363 14.6285 7.72363C14.8588 7.72363 15.0797 7.81514 15.2426 7.97802C15.4055 8.14089 15.497 8.36181 15.497 8.59215V9.46067C15.497 9.69101 15.4055 9.91192 15.2426 10.0748C15.0797 10.2377 14.8588 10.3292 14.6285 10.3292C14.3981 10.3292 14.1772 10.2377 14.0143 10.0748C13.8514 9.91192 13.7599 9.69101 13.7599 9.46067V8.59215ZM13.0174 12.0662H11.005C10.4978 12.0662 10.0262 12.7749 10.4509 13.3455C10.7392 13.7329 11.4636 14.5076 12.0498 14.5076C12.6361 14.5076 13.3239 13.7329 13.594 13.3455C14.0622 12.6125 13.4334 12.0766 13.0174 12.0662ZM12.4572 15.1199C12.4572 15.0048 12.4114 14.8943 12.33 14.8129C12.2485 14.7314 12.1381 14.6857 12.0229 14.6857H12.0133C11.8982 14.6857 11.7877 14.7314 11.7063 14.8129C11.6248 14.8943 11.5791 15.0048 11.5791 15.1199C11.5791 15.1911 11.58 15.2624 11.5834 15.3318C11.4827 17.26 10.0149 18.1319 9.01001 18.1319C8.89484 18.1319 8.78438 18.1777 8.70294 18.2591C8.6215 18.3406 8.57575 18.451 8.57575 18.5662C8.57575 18.6814 8.6215 18.7918 8.70294 18.8733C8.78438 18.9547 8.89484 19.0005 9.01001 19.0005C10.0618 19.0005 11.355 18.3525 12.0177 17.0532C12.6812 18.3525 13.9745 19.0005 15.0254 19.0005C15.1405 19.0005 15.251 18.9547 15.3324 18.8733C15.4139 18.7918 15.4596 18.6814 15.4596 18.5662C15.4596 18.451 15.4139 18.3406 15.3324 18.2591C15.251 18.1777 15.1405 18.1319 15.0254 18.1319C14.0214 18.1319 12.5536 17.26 12.4528 15.3318C12.4556 15.2612 12.457 15.1906 12.4572 15.1199Z"
      fill="#0F0F13"
    />
  </svg>
)
