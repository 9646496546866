import type { IconProps } from './IconsTypes'

export const ArrowleftIcons = ({
  width = 8,
  height = 14,
  viewBox = '0 0 8 14',
  color = '#14181F',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.292 13.54a1 1 0 0 0 1.414-1.414l-1.414 1.415ZM1.166 7l-.707-.707a1 1 0 0 0 0 1.414L1.166 7Zm6.54-5.126A1 1 0 0 0 6.292.46l1.414 1.414Zm0 10.252L1.873 6.293.46 7.707l5.833 5.833 1.414-1.414ZM1.873 7.707l5.833-5.833L6.292.46.46 6.293l1.414 1.414Z"
      fill={color}
    />
  </svg>
)
