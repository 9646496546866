import type { IconProps } from './IconsTypes'

export const MenuMobileCasa = ({ width = 14, height = 20 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.66211"
      y="10.2168"
      width="20"
      height="13.0081"
      rx="2.6"
      fill="#FFCC4D"
    />
    <path
      d="M9.18555 17.0158C9.18555 16.2979 9.76758 15.7158 10.4855 15.7158H12.8379C13.5559 15.7158 14.1379 16.2979 14.1379 17.0158V23.239H9.18555V17.0158Z"
      fill="#F5F5F5"
    />
    <path
      d="M15.2209 16.3156C15.2209 15.5977 15.8029 15.0156 16.5209 15.0156H18.2542C18.9722 15.0156 19.5542 15.5977 19.5542 16.3156V17.4148C19.5542 18.1328 18.9722 18.7148 18.2542 18.7148H16.5209C15.8029 18.7148 15.2209 18.1328 15.2209 17.4148V16.3156Z"
      fill="#F5F5F5"
    />
    <path
      d="M3.45898 16.3156C3.45898 15.5977 4.04101 15.0156 4.75898 15.0156H6.49232C7.21029 15.0156 7.79232 15.5977 7.79232 16.3156V17.4148C7.79232 18.1328 7.21029 18.7148 6.49232 18.7148H4.75898C4.04101 18.7148 3.45898 18.1328 3.45898 17.4148V16.3156Z"
      fill="#F5F5F5"
    />
    <rect
      x="16.4277"
      y="2.94336"
      width="3.33333"
      height="4.87805"
      rx="1"
      fill="#DBB300"
    />
    <path
      d="M10.0528 1.82647C10.7472 1.25376 11.7387 1.21549 12.4752 1.73297L22.2522 8.60261C23.8507 9.72575 23.0561 12.239 21.1024 12.239H2.99603C1.12037 12.239 0.276484 9.88953 1.72349 8.69611L10.0528 1.82647Z"
      fill="#70707C"
    />
  </svg>
)
