import type { IconProps } from './IconsTypes'

export const BrandIcon = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1.18 6.74-.089-.745.089.745Zm-.234.72.509-.551-.51.55Zm3.699 3.42-.51.551.51-.55Zm.125.387.736.147-.736-.147Zm-.982 4.942-.735-.146.735.146Zm.613.445-.366-.655.366.655Zm4.396-2.461.367.654-.367-.654Zm.407 0-.366.654.366-.654Zm4.396 2.46.367-.654-.367.655Zm.613-.444-.736.146.736-.146Zm-.982-4.941.736-.147-.736.146Zm.126-.388.509.55-.51-.55Zm3.698-3.42-.509-.55.51.55Zm-.233-.72.088-.745-.088.745Zm-5.004-.593-.088.745.088-.745Zm-.329-.24-.68.315.68-.314ZM9.38 1.334l.681-.315-.68.315Zm-.757 0-.68-.315.68.315Zm-2.11 4.575.681.314-.68-.314Zm-.33.239.089.745-.088-.745Zm-5.09-.152c-.99.117-1.386 1.34-.655 2.015L1.455 6.91a.333.333 0 0 1-.187.576l-.177-1.49ZM.436 8.01l3.699 3.421 1.018-1.101-3.699-3.421L.437 8.01Zm3.699 3.421a.333.333 0 0 1-.101-.31l1.47.293a1.167 1.167 0 0 0-.351-1.084L4.136 11.43Zm-.101-.31-.982 4.942 1.471.292.982-4.941-1.471-.293Zm-.982 4.942c-.194.977.846 1.731 1.714 1.245L4.035 16a.333.333 0 0 1 .489.356l-1.471-.292Zm1.714 1.245 4.397-2.46-.733-1.31L4.035 16l.732 1.31Zm4.397-2.46a.333.333 0 0 1-.326 0l.733-1.31a1.167 1.167 0 0 0-1.14 0l.733 1.31Zm-.326 0 4.396 2.46.733-1.309-4.396-2.46-.733 1.308Zm4.396 2.46c.869.486 1.908-.269 1.714-1.245l-1.471.292a.333.333 0 0 1 .49-.356l-.733 1.31Zm1.714-1.245-.981-4.942-1.472.293.982 4.941 1.471-.292Zm-.981-4.942a.333.333 0 0 1-.101.31l-1.019-1.101a1.167 1.167 0 0 0-.352 1.084l1.472-.293Zm-.101.31 3.698-3.42-1.018-1.102-3.699 3.42 1.019 1.102Zm3.698-3.42c.731-.676.335-1.899-.654-2.016l-.177 1.49a.333.333 0 0 1-.187-.576l1.018 1.101Zm-.654-2.016-5.004-.593-.176 1.49 5.003.593.177-1.49Zm-5.004-.593a.334.334 0 0 1 .264.191l-1.362.629c.17.367.518.622.922.67l.176-1.49Zm.264.191-2.11-4.575-1.362.629 2.11 4.575 1.363-.629Zm-2.11-4.575c-.417-.904-1.702-.903-2.119 0l1.362.629a.333.333 0 0 1-.605 0l1.362-.629Zm-2.119 0-2.11 4.575 1.362.629 2.11-4.575-1.362-.629Zm-2.11 4.575a.333.333 0 0 1 .263-.191l.177 1.49c.402-.048.752-.301.922-.67l-1.362-.629Zm.263-.191-5.003.593.177 1.49 5.003-.593-.177-1.49Z"
      fill={color}
    />
  </svg>
)
