import { ArcaplanetDisabledMapLocationPin } from 'src/components/icons/ArcaplanetDisabledMapLocationPin'
import { ArcaplanetFastMapLocationPin } from 'src/components/icons/ArcaplanetFastMapLocationPin'
import { ArcaplanetMapLocationPin } from 'src/components/icons/ArcaplanetMapLocationPin'
import { delivery, fast_picking } from '../../../../store.config'
import {
  ShippingEstimateCaseEnum,
  ShippingEstimateCaseProps,
  StoreData,
} from './typings'

export const getNormalizedAddressId = (addressId: string) => {
  let result = addressId.replace(/AGRI|(_|-)Franchise/g, '')
  return result
}

export const getPickinTimeData = (list: any) => {
  const result = new Map()
  list &&
    list?.forEach((obj: any) => {
      if (obj?.pickupStoreInfo && obj.pickupStoreInfo?.address) {
        const fastMatch = obj.pickupStoreInfo?.address?.addressId?.endsWith(
          'Franchise'
        )
          ? obj.pickupStoreInfo.address.addressId
          : null

        const slowMatch =
          obj.pickupStoreInfo?.address?.addressId?.match(/AGRI[A-Z0-9]{2}$/)

        if (fastMatch) {
          const fastNormalizedId = fastMatch
          result.set(getNormalizedAddressId(fastNormalizedId), {
            sellerId: obj.pickupPointId.replace(
              `_${obj.pickupStoreInfo.address.addressId}`,
              ''
            ),
            vtexSellerId: fastNormalizedId,
            fastTime: obj.shippingEstimate,
            fastDate: obj.shippingEstimateDate,
          })
        }

        if (slowMatch) {
          const slowNormalizedId = slowMatch[0]
          if (
            !result?.has(getNormalizedAddressId(slowNormalizedId)) ||
            !result.get(getNormalizedAddressId(slowNormalizedId)).fastTime
          ) {
            result.set(getNormalizedAddressId(slowNormalizedId), {
              sellerId: null,
              vtexSellerId: slowNormalizedId,
              slowTime: obj.shippingEstimate,
              slowDate: obj.shippingEstimateDate,
            })
          }
        }
      }
    })

  return result
}

export const getStoreListData = (list: any, storePickingTimeData: any) => {
  let result: Array<StoreData> = []

  list.map((el: any) => {
    const storeData = getStoreData(storePickingTimeData, el.data?.id)

    result.push({
      id: el?.data?.id,
      street: el?.data?.address?.line1,
      city: el?.data?.address?.city,
      region: el?.data?.address?.region,
      postalCode: el?.data?.address?.postalCode,
      fastTime: storeData?.fastTime,
      slowTime: storeData?.slowTime,
      sellerId: storeData?.sellerId,
      vtexSellerId: storeData?.vtexSellerId,
      hours: el?.data.hours,
      icon: getMapLocationIcon(storeData?.fastTime, storeData?.slowTime),
      distance: el?.distance,
      latitude: el?.data?.yextDisplayCoordinate?.latitude,
      longitude: el?.data?.yextDisplayCoordinate?.longitude,
      slowDate: storeData?.slowDate,
      fastDate: storeData?.fastDate,
      geoCoordinates: [
        el?.data.yextDisplayCoordinate.longitude,
        el?.data.yextDisplayCoordinate.latitude,
      ],
    })
  })

  return result
}

export const getMapLocationIcon = (fastTime: string, slowTime: string) => {
  let result: JSX.Element

  if (fastTime) {
    result = <ArcaplanetFastMapLocationPin />
  } else if (slowTime) {
    result = <ArcaplanetMapLocationPin />
  } else {
    result = <ArcaplanetDisabledMapLocationPin />
  }

  return result
}

export const getStoreData = (storeData: any, storeId: string) => {
  return (!storeId && storeData?.get(storeId) !== null) ||
    storeData?.get(storeId) !== undefined
    ? storeData.get(storeId)
    : null
}

export const formatPickingTime = (time: string | null | undefined) => {
  let result: string = ''

  if (time?.includes('bd')) {
    let date = new Date()
    let days = Number(time?.replace(/[a-zA-Z]/g, ''))
    date.setDate(date.getDate() + days)
    result = date.toLocaleDateString()
  }

  if (time?.includes('min')) {
    let minutes = time?.replace(/[a-zA-Z]/g, '')
    result = `${minutes} minuti`
  }

  if (time?.includes('h')) {
    let hours = time?.replace(/[a-zA-Z]/g, '')
    result = `${hours} ore`
  }

  return result
}

export const translateDay = (day: string) => {
  const daysOfWeek: any = {
    monday: 'Lunedì',
    tuesday: 'Martedì',
    wednesday: 'Mercoledì',
    thursday: 'Giovedì',
    friday: 'Venerdì',
    saturday: 'Sabato',
    sunday: 'Domenica',
  }

  return daysOfWeek[day]
}

export const getSessionFavouriteStore = () => {
  const request = sessionStorage.getItem('favourite_store')
  return request && JSON.parse(request)
}

export const getSessionFavouriteStorePPData = () => {
  const request = sessionStorage.getItem('favourite_store_pp_data')
  return request && JSON.parse(request)
}

export const getShippingRangeDate = () => {
  return {
    from: toItalianLocaleDateString(
      new Date(new Date().setDate(new Date().getDate() + delivery.from))
    ),
    to: toItalianLocaleDateString(
      new Date(new Date().setDate(new Date().getDate() + delivery.to))
    ),
  }
}

export const hasToShowIcon = (
  showOnlyFast: boolean | undefined,
  fastTime: any
) => {
  return showOnlyFast ? fastTime !== null && fastTime !== undefined : true
}

export const getFormattedSingleDigit = (singleDigit: number) => {
  return singleDigit < 10 ? '0' + singleDigit : singleDigit
}

export const isSameDate = function (firstDate: Date, secondDate: Date) {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  )
}

const getHoursDifference = (date1: Date, date2: Date) => {
  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime())
  return diffInMilliseconds / (1000 * 60 * 60)
}

export const getShippingEstimateCase = ({
  fastTime,
  fastDate,
  slowDate,
}: ShippingEstimateCaseProps) => {
  const shippingEstimateDateLocale = fastTime
    ? new Date(fastDate)
    : new Date(slowDate)

  const currentDate = new Date()
  const hoursDifference = getHoursDifference(
    shippingEstimateDateLocale,
    currentDate
  )

  if (
    hoursDifference <= fast_picking &&
    isSameDate(shippingEstimateDateLocale, currentDate)
  ) {
    return ShippingEstimateCaseEnum.FastTimeMinLimitHours
  }

  if (
    hoursDifference > fast_picking &&
    isSameDate(shippingEstimateDateLocale, currentDate)
  ) {
    return ShippingEstimateCaseEnum.FastTimeMoreThanMinLimitHours
  }

  if (isTomorrow(shippingEstimateDateLocale)) {
    return ShippingEstimateCaseEnum.FastTimeAfterOpen
  }

  return ShippingEstimateCaseEnum.SlowTime
}

export const isTomorrow = (inputDate: Date) => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  const date = new Date(inputDate)

  return (
    date.getFullYear() === tomorrow.getFullYear() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getDate() === tomorrow.getDate()
  )
}

export const roundUpToNextQuarterHour = (date: Date) => {
  if (!date) {
    return date
  }

  var minutes = date.getMinutes()

  if (minutes > 0 && minutes < 15) {
    date.setMinutes(15)
    return date
  }

  if (minutes > 15 && minutes < 30) {
    date.setMinutes(30)
    return date
  }

  if (minutes > 30 && minutes < 45) {
    date.setMinutes(45)
    return date
  }

  if (minutes > 45) {
    date.setHours(date.getHours() + 1)
    date.setMinutes(0)
    return date
  }

  return date
}

export const toItalianLocaleDateString = (date: Date) => {
  return date.toLocaleDateString('it-IT', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}
