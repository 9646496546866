import type { IconProps } from './IconsTypes'

export const MenuMobileArcacard = ({
  width = 14,
  height = 20,
  viewBox = '5 0 14 20',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4H14C17.771 4 19.657 4 20.828 5.172C21.672 6.015 21.908 7.229 21.974 9.25H2.026C2.092 7.229 2.328 6.015 3.172 5.172C4.343 4 6.229 4 10 4ZM14 20H10C6.229 20 4.343 20 3.172 18.828C2 17.657 2 15.771 2 12C2 11.558 2 11.142 2.002 10.75H21.998C22 11.142 22 11.558 22 12C22 15.771 22 17.657 20.828 18.828C19.657 20 17.771 20 14 20ZM6 15.25C5.80109 15.25 5.61032 15.329 5.46967 15.4697C5.32902 15.6103 5.25 15.8011 5.25 16C5.25 16.1989 5.32902 16.3897 5.46967 16.5303C5.61032 16.671 5.80109 16.75 6 16.75H10C10.1989 16.75 10.3897 16.671 10.5303 16.5303C10.671 16.3897 10.75 16.1989 10.75 16C10.75 15.8011 10.671 15.6103 10.5303 15.4697C10.3897 15.329 10.1989 15.25 10 15.25H6ZM12.5 15.25C12.3011 15.25 12.1103 15.329 11.9697 15.4697C11.829 15.6103 11.75 15.8011 11.75 16C11.75 16.1989 11.829 16.3897 11.9697 16.5303C12.1103 16.671 12.3011 16.75 12.5 16.75H14C14.1989 16.75 14.3897 16.671 14.5303 16.5303C14.671 16.3897 14.75 16.1989 14.75 16C14.75 15.8011 14.671 15.6103 14.5303 15.4697C14.3897 15.329 14.1989 15.25 14 15.25H12.5Z"
      fill="#FFD500"
    />
  </svg>
)
