import type { IconProps } from './IconsTypes'

export const MenuMobileRettili = ({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill={color}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.67 13.96H5.293a2.289 2.289 0 0 1-2.283-2.283v-.327a2.289 2.289 0 0 1 2.283-2.283h9.375a2.289 2.289 0 0 1 2.283 2.283v.327a2.284 2.284 0 0 1-2.283 2.283ZM8.885 5.893H7.562A2.289 2.289 0 0 1 5.28 3.61v-.327A2.289 2.289 0 0 1 7.562 1h4.086a2.289 2.289 0 0 1 2.283 2.283v5.784"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.694 18.853H3.275A2.289 2.289 0 0 1 .992 16.57v-.327a2.289 2.289 0 0 1 2.283-2.283h13.42a2.289 2.289 0 0 1 2.282 2.283v.327a2.293 2.293 0 0 1-2.283 2.283Z"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.082 9.067a2.85 2.85 0 0 1-2.805 2.366A2.845 2.845 0 0 1 4.5 9.22"
      fill="#000"
    />
    <path
      d="M10.082 9.067a2.85 2.85 0 0 1-2.805 2.366A2.845 2.845 0 0 1 4.5 9.22"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.757 13.849a2.854 2.854 0 0 1-2.84 2.596 2.849 2.849 0 0 1-2.825-2.478"
      fill="#000"
    />
    <path
      d="M16.757 13.849a2.854 2.854 0 0 1-2.84 2.596 2.849 2.849 0 0 1-2.825-2.478"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.247 18.846a2.85 2.85 0 0 1 2.819-2.443 2.85 2.85 0 0 1 2.819 2.443"
      fill="#000"
    />
    <path
      d="M3.247 18.846a2.85 2.85 0 0 1 2.819-2.443 2.85 2.85 0 0 1 2.819 2.443M8.885 5.893v3.174M5.28 3.095H3.246L1.952 1.696M3.248 3.095 2.07 4.397"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.881 4.215a.563.563 0 1 0 0-1.127.563.563 0 0 0 0 1.127Z"
      fill="#000"
    />
  </svg>
)
