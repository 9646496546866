import { useSession } from '@faststore/sdk'
import { ButtonLink } from 'src/components/ui/Button'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { UpdatedUserNew } from 'src/components/icons/UpdatedUserNew'

const ButtonSignIn = () => {
  const { person } = useSession()
  const { isMobile } = useBreakpoint()

  return (
    <ButtonLink
      data-fs-button-signin-link
      to={person?.id ? '/account' : '/login'}
      className="text__title-mini"
      variant="tertiary"
    >
      <UpdatedUserNew width={isMobile ? 24 : 32} height={isMobile ? 24 : 32} />
      <span className="button-text">
        {person?.id ? person.givenName : 'Accedi o Registrati'}
      </span>
    </ButtonLink>
  )
}

export default ButtonSignIn
