import type { IconProps } from './IconsTypes'

export const UpdatedUserNew = ({
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  color = '#14181F',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    color={color}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.333 9.334a5.667 5.667 0 1 1 11.334 0 5.667 5.667 0 0 1-11.334 0ZM16 1.667A7.667 7.667 0 1 0 16 17a7.667 7.667 0 0 0 0-15.333ZM5 28c0-1.683 1.114-3.077 3.193-4.103 2.073-1.024 4.91-1.563 7.807-1.563 2.897 0 5.734.54 7.807 1.563C25.886 24.924 27 26.317 27 28a1 1 0 1 0 2 0c0-2.76-1.886-4.7-4.307-5.896-2.427-1.199-5.59-1.77-8.693-1.77-3.103 0-6.266.571-8.693 1.77C4.886 23.299 3 25.239 3 28a1 1 0 1 0 2 0Z"
      fill={color}
      fillOpacity={0.92}
    />
  </svg>
)
