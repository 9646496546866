import { Fragment, useState } from 'react'
import SlideOver from 'src/components/ui/SlideOver'
import { ArrowRightMenuCustom } from 'src/components/icons/ArrowRightMenuCustom'
import { getRenderMenuIcon } from 'src/utils/getRenderMenuIcon'
import { XCloseCustom } from 'src/components/icons/XCloseCustom'
import { useModal } from 'src/sdk/ui/modal/Provider'

import mainMenuMobile from './mainMenuMobile.json'
import subMenuMobile from './subMenuMobile.json'
import SecondaryModal from '../SecondaryModal/SecondaryModal'
import HeaderMainModal from './HeaderMainModal'

import './mainmenu.scss'

interface MenuContentMobile {
  title: string
  iconName?: string
}

interface Props {
  title: string
}

interface SubMenuContentMobile {
  label: string
  link: string
}

interface PropsMainMenu {
  closeMainModal: (a: boolean) => void
}

const MainModal = ({ closeMainModal }: PropsMainMenu) => {
  const [showMenu, setShowMenu] = useState(false)
  const [category, setCategory] = useState('')
  const { onModalClose } = useModal()

  const handleClick = ({ title }: Props) => {
    setShowMenu(true)
    setCategory(title)
  }

  const handleCloseSlideOver = () => {
    onModalClose()
    setShowMenu(false)
  }

  const closeSecondarymodal = () => {
    setShowMenu(false)
  }

  if (showMenu && category) {
    return (
      <SlideOver
        isOpen={showMenu}
        onDismiss={handleCloseSlideOver}
        size="full"
        direction="leftSide"
        className="modal__mobile"
      >
        <SecondaryModal
          categoryFirstmodal={category}
          closeModal={closeSecondarymodal}
          submenu={[]}
        />
      </SlideOver>
    )
  }

  return (
    <div className="mainmodal">
      <div className="closeIcon__container">
        <button
          className="mainHearder-close-icon"
          onClick={() => {
            handleCloseSlideOver()
          }}
        >
          <XCloseCustom />
        </button>
      </div>

      <HeaderMainModal />
      <div className="mainmodal__nav">
        {mainMenuMobile.map(({ title, iconName }: MenuContentMobile) => (
          <Fragment key={title}>
            <button
              key={title}
              className="mainmodal__nav--button"
              onClick={() => handleClick({ title })}
            >
              <span className="mainmodal__nav--iconlink">
                {iconName?.length ? getRenderMenuIcon(iconName) : null}
                <button
                  className={`mainmodal__nav--title ${title}`}
                  onClick={() => handleClick({ title })}
                >
                  {title}
                </button>
              </span>

              <div className="arrowRightModal-container">
                <ArrowRightMenuCustom />
              </div>
            </button>
            <hr className="mainmodal__nav--hr" />
          </Fragment>
        ))}
      </div>
      <div className="mainmodal__links">
        {subMenuMobile.map(({ label, link }: SubMenuContentMobile) => (
          <a
            className="modal__span layout__main"
            href={link}
            key={label}
            onClick={() => closeMainModal(false)}
          >
            {label}
          </a>
        ))}
      </div>
    </div>
  )
}

export default MainModal
