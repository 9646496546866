import type { IconProps } from './IconsTypes'

export const ArcalabIcon = ({
  width = 12,
  height = 18,
  viewBox = '0 0 12 18',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 15.596a.75.75 0 0 0 0 1.5v-1.5Zm5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-6.682-8.01.627-.41-.627.41ZM3.5 13.014h.75-.75Zm.833.833v.75-.75Zm3.334 0v-.75.75Zm2.515-4.76.627.412-.627-.411Zm-1.676 3.351-.748-.047.748.047Zm.432-1.444.652.372-.652-.372Zm-5.877 0-.65.372.65-.372Zm.432 1.444.749-.047-.749.047Zm.007 4.659h5v-1.5h-5v1.5ZM6 .596a5.75 5.75 0 0 0-5.75 5.75h1.5A4.25 4.25 0 0 1 6 2.096v-1.5ZM.25 6.346c0 1.163.346 2.247.94 3.152l1.255-.823a4.227 4.227 0 0 1-.695-2.33H.25Zm2.5 6.667c0 .874.709 1.583 1.583 1.583v-1.5a.083.083 0 0 1-.083-.083h-1.5Zm1.583 1.583h3.334v-1.5H4.333v1.5Zm3.334 0c.874 0 1.583-.709 1.583-1.583h-1.5a.083.083 0 0 1-.083.083v1.5Zm3.142-5.098c.595-.905.941-1.99.941-3.152h-1.5c0 .861-.255 1.66-.694 2.33l1.253.822Zm.941-3.152A5.75 5.75 0 0 0 6 .596v1.5a4.25 4.25 0 0 1 4.25 4.25h1.5Zm-2.5 6.667c0-.33 0-.455.005-.529l-1.497-.094c-.008.134-.008.323-.008.623h1.5Zm.306-4.338c-.78 1.188-1.202 1.829-1.27 1.946l1.304.744a12.184 12.184 0 0 1 .083-.131 67 67 0 0 1 .236-.364l.9-1.372-1.253-.823Zm-.301 3.809c.008-.12.013-.22.017-.304l.01-.198c.007-.105.012-.11.009-.097-.004.013-.006.005.046-.086.025-.045.056-.097.1-.171l.153-.263-1.303-.744c-.055.097-.102.177-.143.247l-.11.189a1.88 1.88 0 0 0-.18.398c-.048.159-.06.306-.069.432l-.011.218-.016.285 1.497.094ZM1.191 9.498a856.497 856.497 0 0 1 1.2 1.836c.018.028.022.035.02.03l1.302-.743c-.067-.117-.488-.758-1.268-1.946l-1.254.823Zm3.059 3.515c0-.3 0-.489-.008-.623l-1.497.094c.005.074.005.2.005.529h1.5Zm-1.84-1.648.153.263.1.171c.052.092.05.1.046.086-.004-.013.002-.008.009.097l.01.198.017.304 1.497-.094-.016-.285-.011-.218a1.879 1.879 0 0 0-.069-.432 1.88 1.88 0 0 0-.18-.399l-.11-.188c-.041-.07-.088-.15-.143-.247l-1.303.744Z"
      fill={color}
    />
  </svg>
)
