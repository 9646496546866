export const mockImage = {
  cane: [
    {
      src: 'purinaProPlan2.jpg',
      alt: 'image brand Purina Pro Plan',
      url: '/brand/purina-pro-plan',
    },
    {
      src: 'hi-pork.webp',
      alt: 'image brand Hi Pork',
      url: '/brand/hi-pork',
    },
    {
      src: 'virtusNoSubtitle.webp',
      alt: 'image brand virtus',
      url: '/brand/virtus',
    },
    {
      src: 'hills.png',
      alt: 'image brand Hill\'s',
      url: '/brand/hills',
    },
    {
      src: 'expecialPro.webp',
      alt: 'image brand Expecial Pro',
      url: '/brand/expecial-pro',
    },
    {
      src: 'nextDog.webp',
      alt: 'image brand Next',
      url: '/brand/next-dog',
    },
    {
      src: 'petAround.webp',
      alt: 'image brand Pet Around You',
      url: '/brand/pet-around-you',
    },
    {
      src: 'inthenature.webp',
      alt: 'image brand In The Nature',
      url: '/brand/in-the-nature',
    },
  ],
  gatto: [
    {
      src: 'purinaProPlan2.jpg',
      alt: 'image brand Purina Pro Plan',
      url: '/brand/purina-pro-plan',
    },
    {
      src: 'schesirCatDog.jpg',
      alt: 'image brand Schesir',
      url: '/brand/schesir',
    },
    {
      src: 'nextCat.webp',
      alt: 'image brand Next Cat',
      url: '/brand/next-cat',
    },
    {
      src: 'perfect.webp',
      alt: 'image brand Perfect',
      url: '/brand/perfect',
    },
    {
      src: 'virtusNoSubtitle.webp',
      alt: 'image brand virtus',
      url: '/brand/virtus',
    },
    {
      src: 'lovedi.webp',
      alt: 'image brand Lovedi',
      url: '/brand/lovedi',
    },
    {
      src: 'hiFish.webp',
      alt: 'image brand Hi Fish',
      url: '/brand/hi-fish',
    },
    {
      src: 'expecialCat.webp',
      alt: 'image brand Expecial Cat',
      url: '/brand/expecial-cat',
    },
  ],
  pesci: [
    {
      src: 'expecial.webp',
      alt: 'image brand Expecial',
      url: '/brand/expecial',
    },
    {
      src: 'next.webp',
      alt: 'image brand Next',
      url: '/brand/next',
    },
  ],
  tartarughe: [
    {
      src: 'expecial.webp',
      alt: 'image brand Expecial',
      url: '/brand/expecial',
    },
    {
      src: 'next.webp',
      alt: 'image brand Next',
      url: '/brand/next',
    },
  ],
  uccelli: [
    {
      src: 'inthenature.webp',
      alt: 'In the Nature',
      url: '/brand/in-the-nature',
    },
    {
      src: 'expecial.webp',
      alt: 'image brand Expecial',
      url: '/brand/expecial',
    },
    {
      src: 'yes.webp',
      alt: 'Yes',
      url: '/brand/yes',
    },
    {
      src: 'petAround.webp',
      alt: 'image brand Pet Around',
      url: '/brand/pet-around-you',
    },
  ],
  roditori: [
    {
      src: 'realNature.webp',
      alt: 'image brand Real Nature',
      url: '/brand/real-nature',
    },
    {
      src: 'expecial.webp',
      alt: 'image brand Expecial',
      url: '/brand/expecial',
    },
    {
      src: 'yes.webp',
      alt: 'Yes',
      url: '/brand/yes',
    },
    {
      src: 'inthenature.webp',
      alt: 'In the Nature',
      url: '/brand/in-the-nature',
    },
  ],
  casa: [
    {
      src: 'perfect.webp',
      alt: 'image brand Perfect',
      url: '/brand/perfect',
    },
    {
      src: 'lovedi.webp',
      alt: 'image brand Lovedi',
      url: '/brand/lovedi',
    },
    {
      src: 'pet-around-you-outdoor.webp',
      alt: 'image brand Pet Around You Outdoor',
      url: '/brand/pet-around-you',
    },
  ],
  altrianimali: [
    {
      src: 'inthenature.webp',
      alt: 'In the Nature',
      url: '/brand/in-the-nature',
    },
    {
      src: 'expecial.webp',
      alt: 'image brand Expecial',
      url: '/brand/expecial',
    },
    {
      src: 'yes.webp',
      alt: 'Yes',
      url: '/brand/yes',
    },
    {
      src: 'petAround.webp',
      alt: 'image brand Pet Around',
      url: '/brand/pet-around-you',
    },
  ],
  promozione: [
    {
      src: 'virtusNoSubtitle.webp',
      alt: 'image brand virtus',
      url: '/brand/virtus',
    },
    {
      src: 'vivi.webp',
      alt: 'image brand Vivi',
      url: '/brand/vivi-dog',
    },
    {
      src: 'hiFish.webp',
      alt: 'image brand Hi Fish',
      url: '/brand/hi-fish',
    },
    {
      src: 'next.webp',
      alt: 'image brand Next',
      url: '/brand/next-dog',
    },
  ],
  piccolianimali: [
    {
      src: 'expecial.webp',
      alt: 'image brand Expecial',
      url: '/brand/expecial',
    },
    {
      src: 'next.webp',
      alt: 'image brand Next',
      url: '/brand/next',
    },
    {
      src: 'inthenature.webp',
      alt: 'In the Nature',
      url: '/brand/in-the-nature',
    },
    {
      src: 'petAround.webp',
      alt: 'image brand Pet Around',
      url: '/brand/pet-around-you',
    },
    {
      src: 'yes.webp',
      alt: 'Yes',
      url: '/brand/yes',
    },
    {
      src: 'realNature.webp',
      alt: 'image brand Real Nature',
      url: '/brand/real-nature',
    },
    {
      src: 'perfect.webp',
      alt: 'image brand Perfect',
      url: '/brand/perfect',
    },
    {
      src: 'lovedi.webp',
      alt: 'image brand Lovedi',
      url: '/brand/lovedi',
    },
  ],
}
