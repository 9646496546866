import type { IconProps } from './IconsTypes'

export const MastercardIcon = ({
  width = 47,
  height = 30,
  viewBox = '0 0 46 30',
  color = '#F8F8F8',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={0.5} fill={color}>
      <path d="M40.196 1.554a4.833 4.833 0 0 1 4.834 4.833v16.917a4.833 4.833 0 0 1-4.834 4.833H6.363a4.834 4.834 0 0 1-4.833-4.833V6.387a4.833 4.833 0 0 1 4.833-4.833h33.833Zm0-1.208H6.363A6.042 6.042 0 0 0 .321 6.387v16.917a6.042 6.042 0 0 0 6.042 6.042h33.833a6.041 6.041 0 0 0 6.042-6.042V6.387A6.042 6.042 0 0 0 40.196.346Z" />
      <path d="M22.205 10.11a5.74 5.74 0 1 0 0 9.472 7.166 7.166 0 0 1 0-9.473Z" />
      <path d="M27.58 20.585a5.74 5.74 0 1 0 0-11.479 5.74 5.74 0 0 0 0 11.48Z" />
    </g>
  </svg>
)
