import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.GATSBY_APPINSIGHTSKEY ?? '',
    enableAutoRouteTracking: false,
    disableAjaxTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
  },
})

appInsights.loadAppInsights()

export { reactPlugin, appInsights }
