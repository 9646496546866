import type { IconProps } from './IconsTypes'

export const ArcaplanetMobileLogo = ({
  width = 24,
  height = 22,
  viewBox = '0 0 24 22',
  color = 'currentColor',
}: IconProps) => (
  <svg
    id="ArcaplanetMobileLogo"
    width={width}
    height={height}
    color={color}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.227 21.132c-.867 0-1.147-.851-1.149-.86l-.355-1.36a.389.389 0 0 0-.404-.33h-4.72c-.35 0-.39.284-.391.297l-.32 1.402c-.002.01-.222.851-1.1.851H1.593c-.267 0-.473-.077-.61-.232-.218-.245-.18-.589-.179-.603l6.616-18.99c.001-.004.247-.894 1.137-.894h6.692c.905 0 1.154.858 1.156.867l6.783 18.966c.006.026.068.392-.147.65-.132.157-.336.236-.608.236h-6.205Z"
      fill="#000"
      style={{
        mixBlendMode: 'multiply',
      }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.986 12.75a2.034 2.034 0 0 0-1.984-1.592 2.034 2.034 0 0 0 0 4.07c.97 0 1.782-.682 1.984-1.592A2.032 2.032 0 0 0 16 13.193c0-1.123-.91-2.035-2.03-2.035-.969 0-1.781.682-1.984 1.592Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.895 7.841a1.397 1.397 0 1 1-2.323 1.553 1.397 1.397 0 0 1 2.323-1.553ZM8.7 7.499c.129 0 .235.492.235 1.098 0 .607-.106 1.1-.235 1.1-.13 0-.235-.494-.235-1.1 0-.606.106-1.098.235-1.098ZM16.206 7.841a1.398 1.398 0 1 1-2.324 1.554 1.398 1.398 0 0 1 2.324-1.554ZM15.008 7.5c.13 0 .237.492.237 1.098 0 .607-.108 1.1-.237 1.1s-.234-.494-.234-1.1c0-.606.105-1.098.234-1.098Z"
      fill="#FFD500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.496 11.95-.001.006.006.003c.459.743 1.413 1.72 1.466 1.774v.003l.002-.001v-.001c1.02-.994 1.43-1.744 1.453-1.787l.001-.002c-.004-.017-.072-.198-1.477-.198-1.276 0-1.43.161-1.448.2l-.006-.002.004.006Zm1.472.489v.406l.001-.406Z"
      fill="#000"
      style={{
        mixBlendMode: 'multiply',
      }}
    />
  </svg>
)
