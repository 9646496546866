import type { IconProps } from './IconsTypes'

export const MenuMobilePiccoliIcon = ({
  width = 16,
  height = 20,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="4.6412"
      cy="5.38623"
      rx="3.77401"
      ry="4.52881"
      fill="#F3AD61"
    />
    <ellipse
      cx="4.26488"
      cy="5.76451"
      rx="2.64181"
      ry="3.39661"
      transform="rotate(-14.4861 4.26488 5.76451)"
      fill="#FF8368"
    />
    <ellipse
      cx="4.01825"
      cy="4.42008"
      rx="4.01825"
      ry="4.42008"
      transform="matrix(-1 0 0 1 24 0.857422)"
      fill="#F3AD61"
    />
    <ellipse
      cx="2.80424"
      cy="3.60545"
      rx="2.80424"
      ry="3.60545"
      transform="matrix(-0.968208 -0.250145 -0.250145 0.968208 24 2.93311)"
      fill="#FF8368"
    />
    <path
      d="M21.935 11.8037C24.5569 16.3325 21.1803 24.2579 12.1225 23.1257C2.31031 24.2579 -1.08638 17.0873 1.93272 11.8037C4.95193 6.52006 6.11746 2.36865 11.7451 2.36865C16.2739 2.36865 17.7836 4.63306 21.935 11.8037Z"
      fill="#F3AD61"
    />
    <path
      d="M16.3422 16.3277C18.984 22.7435 13.8978 23.1209 11.8134 23.1209C9.72911 23.1209 4.64281 21.9887 7.28463 16.3277C9.22196 12.1763 9.7291 2.36865 11.8134 2.36865C13.8978 2.36865 14.8326 11.8037 16.3422 16.3277Z"
      fill="#FFF6E0"
    />
    <path
      d="M12.9441 16.333C12.9441 16.9583 12.4372 17.4652 11.8119 17.4652C11.1866 17.4652 10.6797 16.9583 10.6797 16.333C10.6797 15.7077 11.0571 15.2008 11.8119 15.9556C12.5667 15.2008 12.9441 15.7077 12.9441 16.333Z"
      fill="#FF96A0"
    />
    <path
      d="M9.16992 15.955C7.40872 15.3854 3.28247 14.588 0.867105 15.955"
      stroke="#81300E"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.209 15.955C16.9702 15.3854 21.0964 14.588 23.5118 15.955"
      stroke="#81300E"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.41602 16.7092C7.28381 16.3318 6.15161 15.9544 3.88721 16.7092"
      stroke="#81300E"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9629 16.7112C17.4622 16.3351 18.217 15.9577 20.4917 16.7112"
      stroke="#81300E"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.54883 18.9726C10.1778 19.6016 11.5113 20.3312 11.8132 18.2178"
      stroke="#81300E"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0781 18.9716C13.4491 19.5997 12.1156 20.3283 11.8137 18.2178"
      stroke="#81300E"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.03293 12.7722C7.40532 12.7722 6.89648 12.2634 6.89648 11.6357V11.0529C6.89648 10.4253 7.40528 9.9165 8.03293 9.9165C8.66053 9.9165 9.16937 10.4253 9.16937 11.0529V11.6357C9.16937 12.2634 8.66053 12.7722 8.03293 12.7722Z"
      fill="black"
    />
    <path
      d="M15.7361 12.7722C15.1084 12.7722 14.5996 12.2634 14.5996 11.6357V11.0529C14.5996 10.4253 15.1084 9.9165 15.7361 9.9165C16.3637 9.9165 16.8725 10.4253 16.8725 11.0529V11.6357C16.8725 12.2634 16.3637 12.7722 15.7361 12.7722Z"
      fill="black"
    />
  </svg>
)
