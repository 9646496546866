import type { IconProps } from './IconsTypes'

export const MagnifyGlassMobile = ({
  width = 18,
  height = 18,
  viewBox = '0 0 20 20',
  color = '#666',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.707 12.293a1 1 0 0 0-1.414 1.414l1.414-1.414Zm4.586 7.414a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM8 14a6 6 0 0 1-6-6H0a8 8 0 0 0 8 8v-2ZM2 8a6 6 0 0 1 6-6V0a8 8 0 0 0-8 8h2Zm6-6a6 6 0 0 1 6 6h2a8 8 0 0 0-8-8v2Zm6 6a6 6 0 0 1-6 6v2a8 8 0 0 0 8-8h-2Zm-1.707 5.707 6 6 1.414-1.414-6-6-1.414 1.414Z"
      fill={color}
    />
  </svg>
)
