/* eslint-disable react-hooks/exhaustive-deps */
import { useSession } from '@faststore/sdk'
import { Person } from '@faststore/sdk/dist/session/Session'
import axios from 'axios'
import type { FC } from 'react'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useCart } from 'src/sdk/cart/useCart'
import {
  LAST_CONTEXT_VALIDATED_STORAGE_KEY,
  MIN_CONTEXT_WAITING_TIME_FOR_REVALIDATION,
  getCartFromOrderForm,
  validateCart,
} from 'src/sdk/cart/validate'

export type SharedCartContext = {
  retrieveSharedCart: () => void
  isRetrievingSharedCart: boolean
  setIsRetrievingSharedCart: (val: boolean) => void
  showSharedCartModal: boolean
  setShowSharedCartModal: (val: boolean) => void
}

const SharedCartContext = createContext({} as SharedCartContext)

export const SharedCartProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isRetrievingSharedCart, setIsRetrievingSharedCart] = useState(
    () => false
  )
  const [showSharedCartModal, setShowSharedCartModal] = useState(() => false)
  const { person } = useSession()
  const {
    id: cartId,
    isValidating: isValidatingLocalCart,
    setCart: setLocaleCart,
  } = useCart()

  const personRef = useRef(person)

  const shouldRevalidateForPersonUpdate = (
    currPerson: Person | null,
    prevPerson: Person | null
  ) => {
    return JSON.stringify(currPerson) !== JSON.stringify(prevPerson)
  }

  const shouldRevalidateForCartUpdate = (lastValidated: string | null) => {
    let currentTime = Date.now()
    let numLastValidated = lastValidated ? Number(lastValidated) : 0
    return (
      // Additional condition for checking if the cart has already been re-validated
      currentTime - numLastValidated > MIN_CONTEXT_WAITING_TIME_FOR_REVALIDATION
    )
  }

  const getSharedCustomerByCartId = useCallback(
    async (orderFormId: string, retry = 0): Promise<string | null> => {
      const { data: savedCustomerRes } = await axios.post(
        '/api/sharedCart/getSharedCustomer',
        {
          orderFormId,
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
        }
      )
      if (savedCustomerRes.errors && retry < 3) {
        return getSharedCustomerByCartId(orderFormId, retry + 1)
      } else {
        return savedCustomerRes.data?.getSavedCustomer
      }
    },
    []
  )

  const getSharedCartByCustomerId = useCallback(
    async (customerId: string, retry = 0): Promise<string | null> => {
      const { data: savedCartRes } = await axios.post(
        '/api/sharedCart/getSharedCart',
        {
          userId: customerId,
          nullOnEmpty: false,
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
        }
      )
      if (savedCartRes.errors && retry < 3) {
        return getSharedCartByCustomerId(customerId, retry + 1)
      } else {
        return savedCartRes.data?.getSavedCart
      }
    },
    []
  )

  const saveCartCustomerAssociation = useCallback(
    async (customerId: string, orderFormId: string) => {
      await axios.post('/api/sharedCart/saveSharedCart', {
        userId: customerId,
        orderFormId,
      })
    },
    []
  )

  const mergeCarts = useCallback(
    async (savedCart: string, currentCart: string) => {
      const { data: mergedOrderForm } = await axios.post(
        '/api/sharedCart/mergeSharedCart',

        {
          savedCart,
          currentCart,
          strategy: 'COMBINE',
        }
      )
      const mergedCart = getCartFromOrderForm(mergedOrderForm.data?.replaceCart)
      const validatedCart = await validateCart(mergedCart)
      if (validatedCart != null) {
        setLocaleCart(validatedCart)
      } else if (savedCart !== cartId) {
        setLocaleCart(mergedCart)
      }
    },
    [cartId]
  )

  const updateLocalCart = useCallback(
    async (orderFormId: string) => {
      const { data: savedOrderForm } = await axios.get(
        `/api/checkout/getCartInformation?orderFormId=${orderFormId}`
      )
      const savedCart = getCartFromOrderForm(savedOrderForm)
      const validatedCart = await validateCart(savedCart)
      if (validatedCart != null) {
        setLocaleCart(validatedCart)
      } else if (orderFormId !== cartId) {
        setLocaleCart(savedCart)
      }
    },
    [cartId]
  )

  const retrieveSharedCart = async () => {
    // 1.
    if (cartId && cartId !== '') {
      const sharedCustomerId = await getSharedCustomerByCartId(cartId)
      // 4.
      if (sharedCustomerId) {
        // 7.
        if (person && person.id) {
          // 8.
          if (person.id !== sharedCustomerId) {
            const sharedCartId = await getSharedCartByCustomerId(person.id)
            // 9.
            if (sharedCartId) {
              await updateLocalCart(sharedCartId) // 11.
            } else {
              await updateLocalCart('') // 10.
            }
          } // else DO NOTHING!
        } else {
          setShowSharedCartModal(() => true)
        }
      } else {
        // 5.
        if (person && person.id) {
          const sharedCartId = await getSharedCartByCustomerId(person.id)
          // 6.
          if (sharedCartId) {
            await mergeCarts(sharedCartId, cartId)
          } else {
            await saveCartCustomerAssociation(person.id, cartId)
          }
        }
      }
    } else {
      // 2.
      if (person && person.id) {
        const sharedCartId = await getSharedCartByCustomerId(person.id)
        // 3.
        if (sharedCartId) {
          await updateLocalCart(sharedCartId)
        } // else DO NOTHING!
      } // else DO NOTHING!
    }
  }

  useEffect(() => {
    if (
      isRetrievingSharedCart === false &&
      isValidatingLocalCart === false &&
      shouldRevalidateForPersonUpdate(person, personRef.current)
    ) {
      setIsRetrievingSharedCart(() => true)
      retrieveSharedCart()
        .then(() => {
          setIsRetrievingSharedCart(() => false)
          personRef.current = person
        })
        .catch(() => {
          setIsRetrievingSharedCart(() => false)
        })
    }
  }, [person])

  useEffect(() => {
    const lastValidated = sessionStorage.getItem(
      LAST_CONTEXT_VALIDATED_STORAGE_KEY
    )
    if (
      isRetrievingSharedCart === false &&
      isValidatingLocalCart === false &&
      shouldRevalidateForCartUpdate(lastValidated)
    ) {
      setIsRetrievingSharedCart(() => true)
      retrieveSharedCart()
        .then(() => {
          sessionStorage.setItem(
            LAST_CONTEXT_VALIDATED_STORAGE_KEY,
            String(Date.now())
          )
          setIsRetrievingSharedCart(() => false)
        })
        .catch(() => {
          sessionStorage.setItem(
            LAST_CONTEXT_VALIDATED_STORAGE_KEY,
            String(Date.now())
          )
          setIsRetrievingSharedCart(() => false)
        })
    }
  }, [isValidatingLocalCart])

  const context = useMemo(
    () => ({
      retrieveSharedCart,
      isRetrievingSharedCart,
      setIsRetrievingSharedCart,
      showSharedCartModal,
      setShowSharedCartModal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [person, isRetrievingSharedCart, showSharedCartModal]
  )

  return (
    <SharedCartContext.Provider value={context}>
      {children}
    </SharedCartContext.Provider>
  )
}

export const useSharedCartContext = () => {
  return useContext(SharedCartContext)
}
