import type { IconProps } from './IconsTypes'

export const VirtualIcon = ({
  width = 14,
  height = 14,
  viewBox = '0 0 14 14',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12.709 1.062.748-.05a.75.75 0 0 0-.699-.698l-.05.748ZM2.69 11.08l-.643.385a.75.75 0 0 0 .257.258l.386-.643Zm-2.054.993a.75.75 0 0 0 1.06 1.06l-1.06-1.06ZM6.41 8.42a.75.75 0 0 0-1.06-1.061l1.06 1.06Zm5.55-7.308c.287 4.311-.735 7.443-2.36 9.069-.802.801-1.758 1.25-2.825 1.32-1.076.07-2.325-.24-3.699-1.065l-.772 1.287c1.573.943 3.122 1.37 4.57 1.274 1.455-.095 2.746-.715 3.786-1.755 2.057-2.057 3.098-5.702 2.796-10.23l-1.497.1Zm-8.627 9.582C2.51 9.32 2.2 8.07 2.27 6.994c.07-1.066.519-2.022 1.32-2.823 1.626-1.627 4.758-2.648 9.069-2.36l.1-1.497C8.23.012 4.585 1.054 2.528 3.11 1.49 4.15.87 5.44.774 6.896c-.096 1.447.33 2.997 1.274 4.57l1.286-.772Zm-1.637 2.44L6.411 8.42l-1.06-1.061-4.714 4.714 1.06 1.06Z"
      fill={color}
    />
  </svg>
)
