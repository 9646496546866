import type { ThumborImageOptions } from '@vtex/gatsby-plugin-thumbor'
import { useGetThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import type { GatsbyImageProps } from 'gatsby-plugin-image'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import { memo, useMemo } from 'react'

interface Props extends Omit<GatsbyImageProps, 'image'> {
  desktop: ThumborImageOptions
  mobile: ThumborImageOptions
  tablet?: ThumborImageOptions
  media?: {
    desktop: string
    tablet?: string
  }
}

function ImageWithArtDirection({
  mobile,
  desktop,
  tablet,
  media = { desktop: '(min-width: 40em)' },
  ...imgProps
}: Props) {
  const getImage = useGetThumborImageData()
  const image = useMemo(() => {
    const mobileImage = getImage(mobile)
    const tabletImage = tablet ? getImage(tablet) : undefined
    const desktopImage = getImage(desktop)

    return tablet && media.tablet && tabletImage
      ? withArtDirection(mobileImage, [
          {
            media: media.tablet,
            image: tabletImage,
          },
          {
            media: media.desktop,
            image: desktopImage,
          },
        ])
      : withArtDirection(mobileImage, [
          {
            media: media.desktop,
            image: desktopImage,
          },
        ])
  }, [desktop, mobile, tablet, getImage, media.desktop, media.tablet])

  return <GatsbyImage {...imgProps} image={image} />
}

export default memo(ImageWithArtDirection)
