import type { IconProps } from './IconsTypes'

export const YoutubeIcon = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  color = '#fff',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 4.846H7c-3 0-5 2-5 5v6c0 3 2 5 5 5h10c3 0 5-2 5-5v-6c0-3-2-5-5-5Zm-3.11 9.03-2.47 1.48c-1 .6-1.82.14-1.82-1.03v-2.97c0-1.17.82-1.63 1.82-1.03l2.47 1.48c.95.58.95 1.5 0 2.07Z"
        fill={color}
      />
    </svg>
  )
}
