export const ArcaplanetFastMapLocationPin = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.654 3.87891C11.9019 3.87891 5.59546 10.3174 5.59546 18.2316C5.59546 26.6457 13.2516 31.8092 18.3176 35.2251L19.1942 35.8196C19.3331 35.9142 19.4935 35.9614 19.6523 35.9614C19.8111 35.9614 19.9715 35.9142 20.1105 35.8196L20.9871 35.2251C26.0531 31.8092 33.7092 26.6457 33.7092 18.2316C33.7125 10.3174 27.406 3.87891 19.654 3.87891Z"
        fill="#0F0F13"
      />
      <path
        d="M23.8522 18.2978C23.6942 17.9135 23.3402 17.6746 22.9282 17.6746L21.0235 17.7217L22.1675 15.0459C22.3015 14.733 22.2702 14.3757 22.0848 14.0917C21.8995 13.807 21.5868 13.6367 21.2495 13.6367H18.2628C17.7042 13.6367 17.2408 13.9894 17.0595 14.5405L15.3148 19.0374C15.2115 19.349 15.2615 19.6801 15.4522 19.9466C15.6428 20.2131 15.9382 20.3666 16.2642 20.3666L18.1468 20.3222L16.2235 25.9779C16.1735 26.126 16.2308 26.2895 16.3622 26.3723C16.4168 26.4066 16.4775 26.4234 16.5388 26.4234C16.6248 26.4234 16.7102 26.3898 16.7748 26.3252L23.6362 19.3982C23.9268 19.1041 24.0095 18.6821 23.8522 18.2978Z"
        fill="white"
      />
    </svg>
  )
}
