import type { IconProps } from './IconsTypes'

export const MenuMobileAltri = ({
  width = 22,
  height = 21,
  viewBox = '0 0 22 21',
  color = '#fff',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.307 7.564a3.193 3.193 0 1 1 6.388 0M5.984 10.81a6.352 6.352 0 0 0 12.704 0V7.564"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.633 7.565a.174.174 0 1 1 .347 0 .174.174 0 0 1-.347 0Z"
      fill="#000"
      stroke="#000"
      strokeWidth={1.5}
    />
    <path
      d="M12.052 17.46v2.333M10.22 19.794h4.478M1.492 4.538c5.542.35 4.66 4.842 4.66 4.842h4.755s1.407.285 1.407-1.815M1.492 7.732s4.055-.554 4.492 3.078M2.112 10.926s2.575-.948 4.142 1.72M9.396 13.158s2.545.576 3.785-1.27M19.066 8.14h1.32"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.683 5.95s-2.409-1.1-.318-2.531c0 0 1.075-2.147 2.747-1.328 0 0 2.004-1.773 3.192.033 0 0 .682 1.388-.67 2.334"
      fill="#000"
    />
    <path
      d="M12.683 5.95s-2.409-1.1-.318-2.531c0 0 1.075-2.147 2.747-1.328 0 0 2.004-1.773 3.192.033 0 0 .682 1.388-.67 2.334-2.729-1.142-4.438.52-4.951 1.492Z"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
