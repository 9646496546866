import { AnalyticsEvent, useAnalyticsEvent } from '@faststore/sdk'

import storeConfig from '../../../store.config'
import type {
  GTMUserEvent,
  RecurringOrderEvent,
  RecurringOrderFrequencyEvent,
  ScrollDepth,
  SearchPreviewClickEvent,
  SearchPreviewEvent,
} from './types'
import { isAnalyticsEvent } from './utils'

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer ?? []
}

type ExtendedEvent =
  | GTMUserEvent
  | AnalyticsEvent
  | SearchPreviewEvent
  | SearchPreviewClickEvent
  | RecurringOrderFrequencyEvent
  | RecurringOrderEvent
  | ScrollDepth

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const handleCartParse = (item: any) => {
  const isKit =
    item.bundle_label &&
    (item.bundle_discount || item.bundle_discount === 0) &&
    (item.bundle_discount_percentage || item.bundle_discount_percentage === 0)

  return {
    item_id: item.item_id,
    item_name: item.item_name,
    coupon: item.coupon,
    currency: item.currency,
    discount: item.discount,
    index: item.index,
    item_brand: item.item_brand,
    item_category: item.item_category,
    item_list_id: item.item_list_id,
    item_list_name: item.item_list_name,
    item_variant: item.item_variant,
    price: item.price,
    quantity: item.quantity,
    full_price: item.full_price,
    discount_percentage: item.discount_percentage,
    item_variant_name: item.item_variant_name,
    item_reference_id: item.product_reference_id,
    // Adding optional kit parameters
    ...(isKit && {
      bundle_label: item.bundle_label,
      bundle_discount: item.bundle_discount,
      bundle_discount_percentage: item.bundle_discount_percentage,
    }),
  }
}

const parseToUniversalAnalyticsData = (evt: ExtendedEvent) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  switch (evt.name) {
    case 'view_item': {
      const isKit =
        evt.params.bundle_label &&
        (evt.params.bundle_discount || evt.params.bundle_discount === 0) &&
        (evt.params.bundle_discount_percentage ||
          evt.params.bundle_discount_percentage === 0)

      return {
        event: 'view_item',
        ecommerce: {
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: evt.params.bundle_label,
            bundle_discount: evt.params.bundle_discount,
            bundle_discount_percentage: evt.params.bundle_discount_percentage,
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            let result: any = ''

            result = handleCartParse(item)
            result.availability = item.availability

            return result
          }),
        },
      }
    }

    case 'select_item': {
      const isKit =
        evt.params.bundle_label &&
        (evt.params.bundle_discount || evt.params.bundle_discount === 0) &&
        (evt.params.bundle_discount_percentage ||
          evt.params.bundle_discount_percentage === 0)

      return {
        event: 'select_item',
        ecommerce: {
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: evt.params.bundle_label,
            bundle_discount: evt.params.bundle_discount,
            bundle_discount_percentage: evt.params.bundle_discount_percentage,
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item: any) => {
            let result: any = ''

            result = handleCartParse(item)

            result.search_term = item.search_term

            return result
          }),
        },
      }
    }

    case 'add_to_cart': {
      const isKit =
        evt.params.bundle_label &&
        (evt.params.bundle_discount || evt.params.bundle_discount === 0) &&
        (evt.params.bundle_discount_percentage ||
          evt.params.bundle_discount_percentage === 0)

      return {
        event: 'add_to_cart',
        ecommerce: {
          orderFormId: evt.params.orderFormId ?? '',
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: evt.params.bundle_label,
            bundle_discount: evt.params.bundle_discount,
            bundle_discount_percentage: evt.params.bundle_discount_percentage,
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item: any) => {
            let result: any = ''

            result = handleCartParse(item)
            result.box_type = item.box_type
            result.box_quantity = item.quantity
            result.box_discount = item.box_discount

            return result
          }),
        },
      }
    }

    case 'remove_from_cart': {
      const isKit =
        evt.params.bundle_label &&
        (evt.params.bundle_discount || evt.params.bundle_discount === 0) &&
        (evt.params.bundle_discount_percentage ||
          evt.params.bundle_discount_percentage === 0)

      return {
        event: 'remove_from_cart',
        ecommerce: {
          orderFormId: evt.params.orderFormId ?? '',
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: evt.params.bundle_label,
            bundle_discount: evt.params.bundle_discount,
            bundle_discount_percentage: evt.params.bundle_discount_percentage,
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item) => handleCartParse(item)),
        },
      }
    }

    case 'view_item_list': {
      return {
        event: 'view_item_list',
        ecommerce: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item) => handleCartParse(item)),
        },
      }
    }

    case 'begin_checkout': {
      const isKit =
        evt.params.bundle_label &&
        (evt.params.bundle_discount || evt.params.bundle_discount === 0) &&
        (evt.params.bundle_discount_percentage ||
          evt.params.bundle_discount_percentage === 0)

      return {
        event: 'begin_checkout',
        ecommerce: {
          orderFormId: evt.params.orderFormId ?? '',
          // Adding optional kit parameters
          currency: evt.params.currency,
          value: evt.params.value,
          ...(isKit && {
            bundle_label: evt.params.bundle_label,
            bundle_discount: evt.params.bundle_discount,
            bundle_discount_percentage: evt.params.bundle_discount_percentage,
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item) => handleCartParse(item)),
        },
      }
    }

    case 'add_to_wishlist': {
      const isKit =
        evt.params.bundle_label &&
        (evt.params.bundle_discount || evt.params.bundle_discount === 0) &&
        (evt.params.bundle_discount_percentage ||
          evt.params.bundle_discount_percentage === 0)

      return {
        event: 'add_to_wishlist',
        ecommerce: {
          currency: evt.params.currency,
          value: evt.params.value,
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: evt.params.bundle_label,
            bundle_discount: evt.params.bundle_discount,
            bundle_discount_percentage: evt.params.bundle_discount_percentage,
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item) => handleCartParse(item)),
        },
      }
    }

    case 'view_cart': {
      const isKit =
        evt.params.bundle_label &&
        (evt.params.bundle_discount || evt.params.bundle_discount === 0) &&
        (evt.params.bundle_discount_percentage ||
          evt.params.bundle_discount_percentage === 0)

      return {
        event: 'view_cart',
        ecommerce: {
          orderFormId: evt.params.orderFormId ?? '',
          // Adding optional kit parameters
          currency: evt.params.currency,
          value: evt.params.value,
          ...(isKit && {
            bundle_label: evt.params.bundle_label,
            bundle_discount: evt.params.bundle_discount,
            bundle_discount_percentage: evt.params.bundle_discount_percentage,
          }),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: (evt.params.items ?? []).map((item) => handleCartParse(item)),
        },
      }
    }

    case 'select_promotion':
      return {
        event: 'ga4_event',
        event_name: 'select_promo_homepage',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        promo: evt.params.promo,
      }

    case 'gtm_user_event':
      return {
        loginStatus: evt.params.loginStatus,
        userID: evt.params.userID,
        visitorType: evt.params.visitorType,
        visitorContactInfo: {
          nome: evt.params.visitorContactInfo.nome,
          cognome: evt.params.visitorContactInfo.cognome,
          email: evt.params.visitorContactInfo.email,
          hashed_email: evt.params.visitorContactInfo.hashed_email,
        },
        visitorDemographicInfo: {
          indirizzo: evt.params.visitorDemographicInfo.indirizzo,
          città: evt.params.visitorDemographicInfo.città,
          provincia: evt.params.visitorDemographicInfo.provincia,
          cap: evt.params.visitorDemographicInfo.cap,
        },
        visitorOptinNewsLetter: evt.params.visitorOptinNewsLetter,
        pageType: evt.params.pageType,
      }

    case 'search_preview_event':
      return {
        event: 'ga4_event',
        event_name: evt.params.event_name,
      }

    case 'search_preview_click_event':
      return {
        event: 'ga4_event',
        event_name: evt.params.event_name,
        clickArea: evt.params.clickArea,
        clicked_result: evt.params.clicked_result,
        search_term: evt.params.search_term,
      }

    case 'recurring_order_frequency_event':
      return {
        event: 'ga4_event',
        event_name: evt.params.event_name,
        frequency: evt.params.frequency,
      }

    case 'recurring_order_event':
      return {
        event: 'ga4_event',
        event_name: evt.params.event_name,
      }

    case 'scroll_depth':
      return {
        event: 'ga4_event',
        event_name: 'scroll_depth',
        scroll_percentage: evt.params.scroll_threshold + '%',
      }

    default: {
      return null
    }
  }
}

export const AnalyticsHandler = () => {
  useAnalyticsEvent((event: ExtendedEvent) => {
    const universalAnalyticsData = parseToUniversalAnalyticsData(event)

    // Cleans the ecommerce object before pushing a new one
    // This prevents the new data from getting merged with the previous one
    // which could lead do inaccurate and old data being sent with events
    //
    // source: https://developers.google.com/tag-manager/ecommerce-ga4?hl=pt-br#clearing_the_ecommerce_object
    if (isAnalyticsEvent(event?.name)) {
      window.dataLayer.push({ ecommerce: null })
    }

    window.dataLayer.push(universalAnalyticsData)

    import(`./platform/${storeConfig.platform}`).then(
      ({ default: sendEvent }) => {
        sendEvent(event)
      }
    )
  })

  return null
}

export default AnalyticsHandler
