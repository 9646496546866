import type { IconProps } from './IconsTypes'

export const TiktokIcon = ({
  width = 24,
  height = 24,
  viewBox = '0 0 300 300',
}: IconProps) => {
  return (
    <svg
      id="TiktokIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <g
        transform="translate(-221.857 -376.148)"
        style={{
          display: 'inline',
        }}
      >
        <rect
          ry={71.309}
          y={376.148}
          x={221.857}
          height={299.999}
          width={299.999}
          style={{
            fill: '#000',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 3.14339042,
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeOpacity: 1,
          }}
        />
        <path
          d="M462.5 465.934c-56.071-5.358-55-54.643-55-54.643h-36.154v155.645c-.912 16.646-14.675 29.676-31.346 29.676-17.338 0-31.393-14.055-31.393-31.393 0-17.337 14.055-31.392 31.393-31.392 3.659 0 7.29.64 10.728 1.89l.355-41.094a69.286 69.286 0 0 0-11.583-.975c-38.265 0-69.286 31.02-69.286 69.286 0 38.265 31.02 69.285 69.286 69.285 36.959 0 67.411-29.009 69.204-65.924v-81.076s19.867 17.143 53.796 17.857z"
          style={{
            display: 'inline',
            opacity: 0.75,
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 1,
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeOpacity: 1,
          }}
        />
        <path
          d="M471.857 475.826c-56.071-5.357-55-54.642-55-54.642h-36.154v155.645c-.912 16.645-14.675 29.676-31.346 29.676-17.338 0-31.392-14.055-31.392-31.393 0-17.337 14.054-31.392 31.392-31.392 3.66 0 7.29.64 10.729 1.89l.354-41.094a69.286 69.286 0 0 0-11.583-.975c-38.265 0-69.286 31.02-69.286 69.285 0 38.266 31.02 69.286 69.286 69.286 36.959 0 67.411-29.009 69.204-65.924v-81.076s19.868 17.143 53.796 17.857z"
          style={{
            display: 'inline',
            opacity: 0.75,
            fill: '#fff',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 1,
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeOpacity: 1,
          }}
        />
      </g>
    </svg>
  )
}
