import { ArrowDown } from 'src/components/icons/ArrowDown'

export const mockMenuGatto = [
  {
    id: 99,
    name: 'Gatto',
    code: 'gatto',
    url: '/gatto',
    image: <ArrowDown />,
    children: [
      {
        id: 86,
        name: 'First column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Cibo secco',
            url: '/gatto/cibo-secco',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Cibo umido',
            url: '/gatto/cibo-umido',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Snack',
            url: '/gatto/snack',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Diete cibo secco',
            url: '/gatto/diete-cibo-secco',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Diete cibo umido',
            url: '/gatto/diete-cibo-umido',
            image: '',
            children: [],
            styles: {
              marginBottom: '25px',
            },
          },
          {
            id: 87,
            name: 'Lettiere',
            url: '/gatto/sabbie',
            image: '',
            children: [
              {
                id: 88,
                name: 'Agglomerante',
                url: '/gatto/sabbie/agglomerante',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Assorbente',
                url: '/gatto/sabbie/assorbente',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Vegetale',
                url: '/gatto/sabbie/vegetale',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Silicio',
                url: '/gatto/sabbie/silicio',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 86,
        name: 'Second column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Accessori',
            url: '/gatto/accessori',
            image: '',
            children: [
              {
                id: 88,
                name: 'Contenitori cibo',
                url: '/gatto/accessori/ciotole-e-dispenser',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Cucce e tappetini',
                url: '/gatto/accessori/cucce-e-lettini',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Giochi',
                url: '/gatto/accessori/giochi',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Guinzaglieria',
                url: '/gatto/accessori/guinzaglieria',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Toelettatura',
                url: '/gatto/accessori/toelettatura',
                image: '',
                children: [],
              },
            ],
          },
          {
            id: 87,
            name: 'Tiragraffi',
            url: '/gatto/tiragraffi',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Trasportini',
            url: '/gatto/trasportini',
            image: '',
            children: [],
          },
        ],
      },
      {
        id: 86,
        name: 'Third column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Igiene',
            url: '/gatto/igiene',
            image: '',
            children: [],
            styles: {
              marginBottom: '25px',
            },
          },
          {
            id: 87,
            name: 'Toilette e accessori',
            url: '/gatto/toilette-e-accessori',
            image: '',
            children: [
              {
                id: 88,
                name: 'Toilette',
                url: '/gatto/toilette-e-accessori/toilette',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Sacchetti toilette',
                url: '/gatto/toilette-e-accessori/sacchetti-toilette',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Tappetini toilette',
                url: '/gatto/toilette-e-accessori/tappetini-toilette',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Palette toilette',
                url: '/gatto/toilette-e-accessori/palette',
                image: '',
                children: [],
              },
            ],
          },
          {
            id: 87,
            name: 'Antiparassitari e curativi',
            url: '',
            image: '',
            children: [
              {
                id: 88,
                name: 'Antiparassitari',
                url: '/gatto/antiparassitari-e-curativi/antiparassitari',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Insettorepellenti',
                url: '/gatto/antiparassitari-e-curativi/insettorepellenti',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Integratori',
                url: '/gatto/antiparassitari-e-curativi/integratori',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Parafarmacia',
                url: '/gatto/antiparassitari-e-curativi/parafarmacia',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
]
