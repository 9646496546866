import type { IconProps } from './IconsTypes'

export const MenuMobilPromocioneIcon = ({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  color = '#CE3535',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.024 9.411a1.386 1.386 0 1 0 0-2.77 1.386 1.386 0 0 0 0 2.77ZM12.384 13.77a1.386 1.386 0 1 0 0-2.772 1.386 1.386 0 0 0 0 2.772Z"
      fill={color}
    />
    <path
      d="m8.025 13.06 4.358-5.853"
      stroke="#CE3535"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4 10c0 .89-1.074 1.6-1.324 2.404-.26.835.207 2.063-.28 2.757-.49.704-1.752.618-2.425 1.126-.665.508-.978 1.791-1.777 2.063-.77.261-1.742-.579-2.594-.579-.852 0-1.825.84-2.595.579-.8-.272-1.112-1.555-1.776-2.063-.674-.513-1.935-.427-2.426-1.126-.487-.694-.02-1.922-.28-2.757C2.673 11.6 1.6 10.895 1.6 10c0-.89 1.073-1.6 1.323-2.404.26-.835-.207-2.062.28-2.757.49-.699 1.752-.613 2.426-1.126.664-.508.977-1.79 1.776-2.062.77-.262 1.743.578 2.595.578.857 0 1.829-.84 2.599-.578.8.271 1.112 1.554 1.776 2.062.674.513 1.935.427 2.426 1.126.487.695.02 1.922.28 2.757.245.81 1.319 1.514 1.319 2.404Z"
      stroke="#CE3535"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
