import type { IconProps } from './IconsTypes'

export const MenuMobilePesciIcon = ({
  width = 21,
  height = 13,
  viewBox = '0 0 21 13',
  color = '#000',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.781 7.31a.701.701 0 1 0 0-1.402.701.701 0 0 0 0 1.403Z"
      fill={color}
    />
    <path
      d="M20.156 7.224c0-1.65-3.62-3.907-3.62-3.907C11.261.574 5.132 6.662 5.132 6.662S4.828 3.656 1.4 3.925c0 0 .117 3.305 2.246 3.305 0 0-2.246-.217-2.246 3.672 0 0 3.433.036 3.738-3.105 0 0 6.129 6.082 11.404 3.345 0-.006 3.614-2.269 3.614-3.918Z"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.824 3.001s-4.79 4.06-.299 8.55"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.349 3.715 7.764 1.41s2.97-.953 4.994 1.2" fill="#000" />
    <path
      d="M9.349 3.715 7.764 1.41s2.97-.953 4.994 1.2"
      stroke="#000"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.126 4.662c-.556-.509-1.41-.89-2.726-.784 0 0 .112 3.217 2.17 3.299-.403.006-2.17.228-2.17 3.673 0 0 1.954.017 3.042-1.322.274-.433.462-.83.596-1.21.047-.182.082-.375.1-.58l.046.047c.094-.468.094-.883.03-1.252a2.121 2.121 0 0 0-.082.076S5.02 5.475 4.126 4.662Z"
      fill="#000"
    />
  </svg>
)
