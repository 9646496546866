import { useSession } from '@faststore/sdk'
import { useMemo } from 'react'

export const useFormattedPrice = (price: number) => {
  const { currency, locale } = useSession()

  // TODO refactorizar el locale para no hardcodearlo
  return useMemo(() => {
    const unformatted = Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency.code,
      currencyDisplay: 'name',
    })
      .format(price)
      .replace(' euro', '')

    return `€${unformatted}`
  }, [currency.code, price, locale])
}
